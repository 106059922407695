/* eslint-disable */
import { Form, Input, Button } from "antd";
import { useEffect, useState } from "react";
import { RxUpdate } from "react-icons/rx";
import { useUpdateCourierType } from "../hooks/useCourierType";
const EditCourierType = ({ courierType, closeEditModal }) => {
  const [initialData, setInitialData] = useState();
  const [form] = Form.useForm();
  const { mutation } = useUpdateCourierType(closeEditModal);
  useEffect(() => {
    setInitialData(courierType);
    form.setFieldsValue(initialData);
  }, [form, initialData, courierType]);

  const onUpdateCourierType = (courierTypee) => {
    mutation.mutate(courierTypee);
  };
  return (
    <Form form={form} onFinish={onUpdateCourierType} layout="vertical">
      <Form.Item name="id" style={{ display: "none" }}>
        <Input size="large" />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "courier type is required",
          },
        ]}
        name="name"
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item>
        <div className="flex flex-row justify-end gap-6 pl-3 mt-2">
          <Button
            htmlType="submit"
            icon={<RxUpdate style={{ fontSize: 22, color: "rgba(139,0,0)" }} />}
            loading={mutation.isLoading}
          />
        </div>
      </Form.Item>
    </Form>
  );
};
export default EditCourierType;
