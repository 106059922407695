/*eslint-disable*/
// @author : Yohannes Assebe
import { Form, Input, Divider, Button, message, Tag, Upload } from "antd";
import { useEffect, useState } from "react";
import { useUpdateRestaurant } from "../hooks/useRestaurant";
import { useQueryClient } from "react-query";
import { PlusOutlined } from "@ant-design/icons";
import Map from "../Admin/map/map";
const fd = new FormData();
const EditRestaurant = ({ restaurantForOperation, closeEditDrawer }) => {
  const [initialData, setInitialData] = useState();
  const [form] = Form.useForm();
  const client = useQueryClient();
  const onSuccess = (data) => {
    message.success(data?.data?.data?.name + " Updated Successfully");
    client.invalidateQueries("allRestaurant");
    closeEditDrawer();
    form.resetFields();
  };

  // checkable tag for days
  const { CheckableTag } = Tag;
  const daysOfWork = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [workingDays, setWorkingDays] = useState([]);

  const handleChanges = (day, checked) => {
    const nextSelectedTags = checked
      ? [...workingDays, day]
      : workingDays.filter((t) => t !== day);
    setWorkingDays(nextSelectedTags);
  };

  const onError = (error) => {
    message.warning(error?.response?.data?.message);
  };
  const { mutate: updateRestaurant, isLoading: load } = useUpdateRestaurant(
    onSuccess,
    onError
  );

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  // upload button for the image of restaurants

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  // set the latitude and longitude for the address of restaurant
  const handleLocation = (data) => {
    fd.delete("address[latitude]");
    fd.delete("address[longitude]");
    fd.append("address[latitude]", data.lat);
    fd.append("address[longitude]", data.lng);
  };

  //  used to handle reset button
  const onReset = () => {
    form.resetFields();
    setWorkingDays([]);
  };

  // handle the update button click
  const onFinish = (values) => {
    Object?.keys(values)?.map((key) => {
      if (Object.is(values[key], undefined) || Object.is(values[key], null)) {
        delete values[key];
        return;
      }
    });

    Object.keys(values)?.map((key) => {
      if (key === "address") {
        Object.keys(values["address"])?.map((addressKey) => {
          fd.append(`address[${addressKey}]`, values["address"][addressKey]);
        });
        delete values["address"];
        return;
      }
      if (key === "upload" && values.upload.length) {
        values[key].map((image) => {
          fd.append("remove_image", 1);
          fd.append("images[]", image.originFileObj, image.originFileObj.name);
        });
        delete values["upload"];
        return;
      }

      fd.append(key, values[key]);
    });
    if (workingDays?.length > 0) {
      workingDays?.map((day, index) => {
        fd.append(`work_days[${index}]`, day);
      });
    } else {
      fd.append(`work_days`, null);
    }

    fd.append("_method", "put");

    // console.table([...fd]);

    updateRestaurant({ id: restaurantForOperation.id, data: fd });
  };

  useEffect(() => {
    setInitialData(restaurantForOperation);
    setWorkingDays(restaurantForOperation?.work_days);
    form.setFieldsValue(initialData);
    // console.log({
    //   lat: initialData?.address?.latitude,
    //   lng: initialData?.address?.lnogitude,
    // });
  }, [form, initialData, restaurantForOperation]);
  return (
    <div className="-mt-5">
      <Divider>Update Restaurant</Divider>
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Restaurant Name"
          rules={[
            {
              required: true,
              message: "Restaurant Name is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="phone_number"
          label="Restaurant Phone Number"
          rules={[
            {
              required: true,
              message: "Restaurant Name is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        {initialData?.address?.latitude && initialData?.address?.longitude && (
          <Map
            google={google}
            center={{
              lat: parseFloat(initialData?.address?.latitude),
              lng: parseFloat(initialData?.address?.longitude),
            }}
            height="300px"
            zoom={12}
            handleLocation={handleLocation}
          />
        )}

        <Divider>Additional Restaurant Address</Divider>
        <Form.Item
          name={["address", "city"]}
          label="City"
          rules={[
            {
              required: true,
              message: "Where Restaurant Founds is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name={["address", "sub_city"]}
          label="SubCity"
          rules={[
            {
              required: true,
              message: "Where Restaurant Founds is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name={["address", "woreda"]}
          label="Woreda"
          rules={[
            {
              required: true,
              message: "Where Restaurant Founds is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name={["address", "house_number"]}
          label="House Number"
          rules={[
            {
              required: true,
              message: "Where Restaurant Founds is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name={["address", "neighborhood"]}
          label="Neighborhood"
          rules={[
            {
              required: true,
              message: "Where Restaurant Founds is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Divider>Opening/Closing Periods</Divider>
        <Form.Item label="Opening Time" name="work_hour_start">
          <input
            type="time"
            step="2"
            className="w-full py-2 px-2 rounded-md border border-gray-300 focus:outline-none focus:border-sky-600 hover:outline-none hover:border-sky-600"
          />
          {/* <TimePicker format="HH:mm" showOk={false} className="w-full" /> */}
        </Form.Item>
        <Form.Item label="Closing Time" name="work_hour_end">
          <input
            type="time"
            step="2"
            className="w-full py-2 px-2 rounded-md border border-gray-300 focus:outline-none focus:border-sky-600 hover:outline-none hover:border-sky-600"
          />

          {/* <TimePicker format="HH:mm" className="w-full" showOk={false} /> */}
        </Form.Item>

        <Form.Item label="Working Days">
          {daysOfWork?.map((day) => (
            <CheckableTag
              style={{ marginRight: 18, marginTop: 4 }}
              key={day}
              checked={workingDays?.indexOf(day) > -1}
              onChange={(checked) => handleChanges(day, checked)}
            >
              {day}
            </CheckableTag>
          ))}
        </Form.Item>
        <Divider>Change Image</Divider>
        <Form.Item
          name="upload"
          label="Images Of Restaurant"
          valuePropName="fileList"
          accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
          getValueFromEvent={normFile}
        >
          <Upload
            name="images"
            listType="picture-card"
            beforeUpload={(file) => {
              return false;
            }}
          >
            {uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item>
          <div className="flex flex-row gap-6 pl-3">
            <Button
              onClick={onReset}
              className="rounded-lg w-32 h-8 border-1 border-[#C82127] text-[#C82127]"
            >
              Clear
            </Button>
            <Button
              htmlType="submit"
              className="rounded-lg w-32 h-8 border-1 border-slate-600"
              loading={load}
            >
              Update
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditRestaurant;
