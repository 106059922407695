// @author Yohannes Assebe
/*eslint-disable*/
import { useState } from "react";
import { List, Avatar, Popconfirm, Drawer, message } from "antd";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useDeleteStoreManager } from "../hooks/useRestaurant";
import { useQueryClient } from "react-query";
import EditRestaurantManager from "./editRestaurantManager";
const RestaurantManager = ({
  restaurantForOperation,
  closeRestaurantManager,
}) => {
  const { mutate: deleteRestaurantManager } = useDeleteStoreManager();
  const client = useQueryClient();
  //restaurant manager for edit
  const [restaurantManagerToEdit, setRestaurantManagerToEdit] = useState();
  // edit restaurant manager drawer controller
  const [editRestaurantManagerDrawer, setEditRestaurantManagerDrawer] =
    useState(false);
  //function to control edit restaurant manager drawer
  const openEditRestaurantManagerDrawer = () => {
    setEditRestaurantManagerDrawer(true);
  };

  const closeEditRestaurantManagerDrawer = () => {
    setEditRestaurantManagerDrawer(false);
  };

  //function to update restaurant manager for edit
  const restaurantManagerForOperation = (manager) => {
    setRestaurantManagerToEdit(manager);
  };

  return (
    <>
      <List
        size="large"
        header={
          <div>
            {
              <div className="font-bold text-center text-2xl font-mono">
                {restaurantForOperation?.name + "'s Managers"}
              </div>
            }
          </div>
        }
        footer={<em>@ZowiTech</em>}
        bordered
        dataSource={restaurantForOperation?.managers}
        renderItem={(manager) => (
          <List.Item key={manager.id}>
            <List.Item.Meta
              title={[manager?.first_name, manager?.last_name].join(" ")}
              avatar={<Avatar src={manager?.profile_image_path} />}
              description={
                <div className="flex flex-row justify-between">
                  <div>{manager?.email}</div>
                  <div className="space-x-1">
                    <AiOutlineEdit
                      className="text-base text-blue-800"
                      onClick={(e) => {
                        e.stopPropagation();
                        restaurantManagerForOperation(manager);
                        closeRestaurantManager();
                        openEditRestaurantManagerDrawer();
                      }}
                    />
                    <Popconfirm
                      title={
                        <div>
                          Delete{" "}
                          {[manager?.first_name, manager?.last_name].join(" ")}
                        </div>
                      }
                      description="Are you sure to delete?"
                      onConfirm={(e) => {
                        e.stopPropagation();
                        deleteRestaurantManager(manager.id, {
                          onSuccess: (data) => {
                            message.warning("Restaurant Manager Deleted");
                            client.invalidateQueries("allRestaurant");
                          },
                          onError: (error) => {
                            message.warning(error?.response?.data?.message);
                          },
                        });
                      }}
                      onCancel={(e) => {
                        e.stopPropagation();
                        message.warning("ምንም ስራ አልተሰራም");
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <RiDeleteBin5Fill
                        className="text-base text-red-500"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    </Popconfirm>
                  </div>
                </div>
              }
            />
          </List.Item>
        )}
      />
      <Drawer
        open={editRestaurantManagerDrawer}
        onClose={closeEditRestaurantManagerDrawer}
        closable={false}
        style={{ width: "100%" }}
      >
        <EditRestaurantManager
          restaurantManagerForOperation={restaurantManagerToEdit}
          closeEditRestaurantManagerDrawer={closeEditRestaurantManagerDrawer}
        />
      </Drawer>
    </>
  );
};
export default RestaurantManager;
