import { useState } from "react";
import { Form, Select, DatePicker, message, Button, Divider } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useQueryClient } from "react-query";
// import { useSearchByName } from "../../hooks/useProducts";
import { useAllProduct } from "../../hooks/useProducts";
import { usePostAdvertisement } from "../../hooks/useSpecialOffer";
import moment from "moment";
const AddSpecialOffers = () => {
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState("");
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { data: products, isLoading: loadproduct } = useAllProduct();
  const { mutate: registerAd, isLoading: loadPostAd } = usePostAdvertisement();
  const client = useQueryClient();

  const searchedProduct = products?.filter(
    (product) =>
      product.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
  );

  // this function disable date before today
  function disabledDate(current) {
    return current && current < moment().startOf("day");
  }

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const onReset = () => {
    form.resetFields();
  };

  // function used to handle add special offer
  const onFinish = (values) => {
    registerAd(values, {
      onSuccess: (data) => {
        message.success(
          data?.data?.data?.product?.name + " registered Succesfully"
        );
        form.resetFields();
        client.invalidateQueries("special-offers");
      },
      onError: (error) => {
        message.warning(error?.reponse?.data?.message);
      },
    });
  };
  return (
    <div className="ml-[2%] w-[30%]">
      <Divider>Register New Special Offer</Divider>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Select Product"
          name="product_id"
          rules={[
            {
              required: true,
              message: "Please Select Product",
            },
          ]}
        >
          <Select
            onSearch={handleSearch}
            filterOption={false}
            optionFilterProp="children"
            showSearch
            loading={loadproduct}
          >
            {searchedProduct?.map((product) => {
              return (
                <Option key={product.id} value={product.id}>
                  <div className="md:flex md:flex-row md:justify-between flex flex-col">
                    <div>{product.name}</div>
                    <div>{product?.store?.name}</div>
                  </div>
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="startEndDate"
          label="Set Start And End Date"
          rules={[
            {
              required: true,
              message: "choose start and end date of offer expiration",
            },
          ]}
        >
          <RangePicker disabledDate={disabledDate} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea />
        </Form.Item>
        <Form.Item>
          <div className="flex flex-row  gap-6 pl-6 mt-6">
            <Button
              onClick={onReset}
              className="rounded-lg w-32 h-8 border-1 border-[#C82127] text-[#C82127]"
            >
              Reset
            </Button>
            <Button
              htmlType="submit"
              loading={loadPostAd}
              className="rounded-lg w-32 h-8 border-1 border-slate-600"
            >
              + Add
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddSpecialOffers;
