/* eslint-disable */
// @author : Yohannes Assebe
import {
  Button,
  Table,
  Drawer,
  Popconfirm,
  Avatar,
  message,
  Switch,
  Badge,
  Tag,
  Spin,
  Input,
  Popover,
  Rate,
} from "antd";
import { useState } from "react";
import {
  useAllRestaurants,
  useDeleteRestaurant,
  useActivateDeactivateRestaurant,
  useOpenCloseRestaurant,
} from "../../hooks/useRestaurant";
import { useDebounce } from "../../hooks/useDebounce";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { BiRestaurant } from "react-icons/bi";
import { FcManager } from "react-icons/fc";
import EditRestaurant from "../../drawer/editRestaurant";
import RestaurantProducts from "../../drawer/restaurantProducts";
import { useQueryClient } from "react-query";
import AddRestaurant from "../../drawer/addRestaurant";
import AddRestaurantManager from "../../drawer/addRestaurantManager";
import RestaurantManager from "../../drawer/restaurantManagers";
import RestaurantRating from "../../drawer/restaurantRatings";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { IoIosAddCircleOutline } from "react-icons/io";
import MapBox from "../map/mapbox";
const Restaurant = () => {
  // control table pagination
  const [page, setPage] = useState(1);
  //variables for drawer controls
  const [editDrawer, setEditDrawer] = useState(false);
  const [restaurantProductDrawer, setRestaurantProductDrawer] = useState(false);
  const [addRestaurantDrawer, setAddRestaurantDrawer] = useState(false);
  const [addRestaurantManagerDrawer, setAddRestaurantManagerDrawer] =
    useState(false);
  const [restaurantManagerDrawer, setRestaurantManagerDrawer] = useState(false);

  //variable used for retaurant operation
  const [restaurantForOperations, setRestaurantForOperations] = useState();

  // variable and functions used to control restaurantRatings Drawer
  const [restaurantRatingVisible, setRestauarntRatingVisible] = useState(false);
  const openRestaurantRatingDrawer = () => setRestauarntRatingVisible(true);
  const closeRestaurantRatinfDrawer = () => setRestauarntRatingVisible(false);

  //react-query client
  const client = useQueryClient();

  //mutation for restaurant delete
  const { mutate: deleteRestaurant } = useDeleteRestaurant();

  // mutation to activate/deactivate as well as open/close restaurant
  const {
    mutate: activateDeactivateRestaurant,
    isLoading: load_activate_deactivate,
  } = useActivateDeactivateRestaurant();
  const { mutate: openCloseRestaurant, isLoading: load_open_close } =
    useOpenCloseRestaurant();
  // function called when activate/deactivate or open/close switch clicked
  const handleActivateDeactivate = (restaurant) => {
    activateDeactivateRestaurant(restaurant, {
      onSuccess: (data) => {
        client.invalidateQueries(["allRestaurant", page]);
        const res = data?.data?.data;
        !res.is_active
          ? message.warning([res?.name, "Deactivated"].join(" "))
          : message.success([res?.name, "Activated Successfully"].join(" "));
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };

  const months = Array.from({ length: 12 }, (e, i) => {
    return new Date(null, i + 1, null).toLocaleDateString("en", {
      month: "short",
    });
  });

  const handleOpenClose = (restaurant) => {
    openCloseRestaurant(restaurant, {
      onSuccess: (data) => {
        const res = data?.data?.data;
        client.invalidateQueries(["allRestaurant", page]);
        res?.is_closed
          ? message.warning([res?.name, "Closed"].join(" "))
          : message.success([res?.name, "Opened"].join(" "));
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };

  //functions to control drawer visiblity
  const openEditDrawer = () => {
    setEditDrawer(true);
  };
  const closeEditDrawer = () => {
    setEditDrawer(false);
  };
  const openRestaurantProductDrawer = () => {
    setRestaurantProductDrawer(true);
  };
  const closeRestaurantProductDrawer = () => {
    setRestaurantProductDrawer(false);
  };
  const restaurantForOperation = (restaurant) => {
    setRestaurantForOperations(restaurant);
  };
  const openAddRestaurantDrawer = () => {
    setAddRestaurantDrawer(true);
    hide();
  };
  const closeAddRestaurantDrawer = () => {
    setAddRestaurantDrawer(false);
  };
  const openAddRestaurantManagerDrawer = () => {
    setAddRestaurantManagerDrawer(true);
    hide();
  };
  const closeAddRestaurantManagerDrawer = () => {
    setAddRestaurantManagerDrawer(false);
  };
  const openRestaurantManagerDrawer = () => {
    setRestaurantManagerDrawer(true);
  };
  const closeRestaurantManagerDrawer = () => {
    setRestaurantManagerDrawer(false);
  };

  const onRow = (record) => {
    return {
      onClick: (event) => {
        restaurantForOperation(record);
        record?.products?.length
          ? openRestaurantProductDrawer()
          : message.warning("No Product Registered in " + record.name);
      },
    };
  };

  const buttons = (
    <div className="flex flex-col space-y-1">
      <Button onClick={openAddRestaurantDrawer} className="border-none">
        <div className="flex flex-row gap-1">
          <BiRestaurant className="text-lg text-[#c82127]" /> + Restaurant
        </div>
      </Button>
      <Button onClick={openAddRestaurantManagerDrawer} className="border-none">
        <div className="flex flex-row gap-1">
          <FcManager className="text-lg text-[#c82127]" /> + Restaurant Manager
        </div>
      </Button>
    </div>
  );

  // controllers +add button popovers
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  // modal controllers for restaurant location
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleLocation = (location) => {
    setSelectedLocation(location);
    setModalVisible(true);
  };
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  //search functionality
  const [searchText, setSearchText] = useState("");
  // function to search restaurant by name
  const search = (searchString) => {
    setSearchText(searchString);
    setPage(1);
  };

  const debouncedValue = useDebounce(searchText, 400);
  const { data: restaurants, isLoading: load } = useAllRestaurants(
    page,
    debouncedValue
  );
  // used to change restaurants pagination
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  const columns = [
    {
      key: "0",
      title: "Image",
      render: (record) => {
        return <Avatar src={record.image_paths && record.image_paths[0]} />;
      },
    },
    { key: "1", title: "Restaurant", dataIndex: "name" },
    {
      key: "2",
      title: "Phone",
      dataIndex: "phone_number",
    },
    {
      key: "3",
      title: "Address",
      dataIndex: ["address", "city"],
    },
    {
      key: "4",
      title: "Products",
      dataIndex: "number_of_products",
      align: "center",
    },
    {
      key: "5",
      title: "Registerd",
      dataIndex: "created_at",
      render: (created_at) => {
        const date = created_at.split("T")[0].split("-");
        const readableDate = [months[date[1] - 1], date[2], ",", date[0]].join(
          " "
        );
        return readableDate;
      },
    },
    {
      key: "6",
      title: "Now",
      dataIndex: "is_opened",
      render: (is_opened, index) => {
        return (
          <Tag
            key={index}
            color={is_opened ? "green" : "gray"}
            className={!is_opened ? "closedTag" : null}
          >
            {is_opened ? "Opened" : "Closed"}
          </Tag>
        );
      },
      align: "center",
    },
    {
      key: "7",
      title: "Restaurant",
      render: (record) => {
        return (
          <div className="space-x-2 text-base">
            <AiOutlineEdit
              className="text-sky-700 hover:cursor-pointer hover:text-lg"
              onClick={(e) => {
                e.stopPropagation();
                restaurantForOperation(record);
                openEditDrawer();
              }}
              title={`update ${record.name}`}
            />
            <Popconfirm
              title="Delete Restaurant"
              description="Are you sure to delete this Restaurant?"
              onConfirm={(e) => {
                e.stopPropagation();
                deleteRestaurant(record.id, {
                  onSuccess: (data) => {
                    message.warning("Restaurant Deleted");
                    client.invalidateQueries("allRestaurant");
                  },
                  onError: (error) => {
                    message.warning(error?.response?.data?.message);
                  },
                });
              }}
              onCancel={(e) => {
                e.stopPropagation();
                message.warning("ምንም ስራ አልተሰራም");
              }}
              okText="Yes"
              cancelText="No"
            >
              <RiDeleteBin5Fill
                className="text-red-500 hover:cursor-pointer hover:text-lg"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                title={`Delete ${record.name}`}
              />
            </Popconfirm>
          </div>
        );
      },
      align: "center",
    },
    {
      key: "8",
      title: "Managers",
      align: "center",
      render: (record) => {
        return (
          <div>
            <Badge size="small" count={record?.managers?.length}>
              <FcManager
                className="text-lg hover:cursor-pointer hover:text-xl"
                onClick={(e) => {
                  e.stopPropagation();
                  restaurantForOperation(record);
                  record?.managers?.length
                    ? openRestaurantManagerDrawer()
                    : message.warning(
                        "Manager is not registered for " + record.name
                      );
                }}
                title={`click to see the managers list of ${record?.name}`}
              />
            </Badge>
          </div>
        );
      },
    },
    {
      key: "8",
      title: "Rating",
      render: (record) => {
        return (
          <div
            className="hover:cursor-pointer w-full h-full"
            onClick={(e) => {
              e.stopPropagation();
              restaurantForOperation(record);
              openRestaurantRatingDrawer();
            }}
          >
            <Rate
              className="text-xs w-fit"
              count={5}
              disabled
              value={record.rating}
            />
          </div>
        );
      },
    },
    {
      key: "10",
      title: "Location",
      align: "center",
      render: (record) => {
        return (
          <HiOutlineLocationMarker
            className="text-[#C82127] text-lg hover:cursor-pointer"
            title="click to see location on a map"
            onClick={(e) => {
              e.stopPropagation();
              handleLocation({
                lat: parseFloat(record.address.latitude),
                lng: parseFloat(record.address.longitude),
              });
            }}
          />
        );
      },
    },
    {
      key: "9",
      title: "Active",
      render: (record) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Switch
              size="small"
              checked={record?.is_active}
              onChange={() => handleActivateDeactivate(record)}
            />
          </div>
        );
      },
    },
    {
      key: "11",
      title: "Closed",
      render: (record) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Switch
              key={record.id}
              size="small"
              checked={record?.is_closed}
              onChange={() => handleOpenClose(record)}
              // disabled={record?.is_closed}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div className="max-w-[85%] mt-3 ml-16">
      <div className="flex flex-row space-x-4 mb-2 w-full">
        <div className="flex flex-row ml-4">
          <Popover
            content={buttons}
            title="Register New"
            placement="leftTop"
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <Button
              className="space-x-1 hover:cursor-pointer mt-1"
              // size="large"
            >
              <IoIosAddCircleOutline className="text-sm" />
              <span className="text-base">New</span>
            </Button>
          </Popover>
        </div>
        <Input.Search
          className="py-1 px-1 hover:border-red-600"
          placeholder="looking for restaurant ?"
          onChange={(e) => search(e.target.value)}
        />
      </div>
      <Table
        className="ml-4"
        dataSource={restaurants?.data}
        columns={columns}
        loading={{
          indicator: <Spin size="small" />,
          spinning: load || load_activate_deactivate || load_open_close,
        }}
        onRow={onRow}
        pagination={{
          current: page,
          total: restaurants?.meta?.last_page * 10,
          showSizeChanger: false,
          position: ["bottomCenter"],
          className: "custom-pagination",
        }}
        rowKey={(record) => record.id}
        rowClassName={(record, index) =>
          !record?.is_opened ? "closedRow" : null
        }
        onChange={handleTableChange}
        bordered
      />
      <Drawer open={editDrawer} onClose={closeEditDrawer} closable={false}>
        <EditRestaurant
          restaurantForOperation={restaurantForOperations}
          closeEditDrawer={closeEditDrawer}
        />
      </Drawer>
      <Drawer
        open={restaurantProductDrawer}
        onClose={closeRestaurantProductDrawer}
        closable={false}
      >
        <RestaurantProducts
          restaurantForOperation={restaurantForOperations}
          closeRestaurantProductDrawer={closeRestaurantProductDrawer}
        />
      </Drawer>
      <Drawer
        open={addRestaurantDrawer}
        onClose={closeAddRestaurantDrawer}
        closable={false}
        width={450}
      >
        <AddRestaurant closeAddRestaurantDrawer={closeAddRestaurantDrawer} />
      </Drawer>
      <Drawer
        open={addRestaurantManagerDrawer}
        onClose={closeAddRestaurantManagerDrawer}
        closable={false}
      >
        <AddRestaurantManager
          closeAddRestaurantManagerDrawer={closeAddRestaurantManagerDrawer}
        />
      </Drawer>

      <Drawer
        open={restaurantManagerDrawer}
        onClose={closeRestaurantManagerDrawer}
        closable={false}
      >
        <RestaurantManager
          restaurantForOperation={restaurantForOperations}
          closeRestaurantManager={closeRestaurantManagerDrawer}
        />
      </Drawer>

      <Drawer
        open={restaurantRatingVisible}
        onClose={closeRestaurantRatinfDrawer}
        closable={false}
      >
        <RestaurantRating
          restaurantForOperation={restaurantForOperations}
          closeRestaurantRatingDrawer={closeRestaurantRatinfDrawer}
        />
      </Drawer>

      {selectedLocation && modalVisible && (
        <MapBox
          visible={modalVisible}
          latitude={selectedLocation.lat}
          longitude={selectedLocation.lng}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default Restaurant;
