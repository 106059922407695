// @author: Yohannes Assebe
/*eslint-disable */
import { useEffect, useState } from "react";
import {
  Form,
  Button,
  Divider,
  message,
  Input,
  InputNumber,
  Upload,
} from "antd";
import { useQueryClient } from "react-query";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { useEditVehicle } from "../hooks/useVehicles";
const EditVehicle = ({ vehicleForOperations, closeEditVehicleDrawer }) => {
  const [form] = Form.useForm();
  const [initialData, setInitialData] = useState();
  //react-query client
  const client = useQueryClient();
  const { mutate: updateVehicle, isLoading: load } = useEditVehicle();
  const onSuccess = (data) => {
    message.success(data?.data?.data?.name + " Updated");
    client.invalidateQueries("allVehicles");
    closeEditVehicleDrawer();
  };
  const onError = (error) => {
    message.warning(error?.response?.data?.message);
  };
  //initialize mutation here with onSuccess and onError
  const onFinish = (values) => {
    const fd = new FormData();
    Object.keys(values).map((key) => {
      if (key === "upload" && values["upload"]?.length) {
        fd.append(
          "image",
          values["upload"][0].originFileObj,
          values["upload"][0].originFileObj.name
        );
        delete values["upload"];
        fd.append("remove_image", 1);
      }
      fd.append(key, values[key]);
      fd.append("_method", "put");
    });
    //registeration Mutation goes here
    updateVehicle(
      { id: vehicleForOperations?.id, data: fd },
      {
        onSuccess,
        onError,
      }
    );
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  useEffect(() => {
    setInitialData(vehicleForOperations);
    form.setFieldsValue(initialData);
  }, [form, initialData, vehicleForOperations]);

  return (
    <div className="-mt-4">
      <Divider>
        <h3>Update Vehicle</h3>
      </Divider>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={initialData}
        layout="vertical"
      >
        <Form.Item
          name="name"
          label="Vehicle Type Name"
          rules={[
            {
              required: true,
              message: "Vehicle Type Name is Required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="name_am"
          label="Vehicle Type Name in Amharic"
          rules={[
            {
              required: true,
              message: "Vehicle TypeName in Amharic is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Vehicle Type Description"
          rules={[
            {
              required: true,
              message: "Delivery Type Description is Required",
            },
          ]}
        >
          <TextArea maxLength={200} showCount />
        </Form.Item>
        <Form.Item
          name="description_am"
          label="Delivery Type Description in Amharic"
          rules={[
            {
              required: true,
              message: "Delivery Type Description in Amharic is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="initial_cost"
          label="Initial Cost"
          rules={[
            {
              required: true,
              message: "Initial Cost is required",
            },
          ]}
        >
          <InputNumber size="large" className="min-w-full" />
        </Form.Item>

        <Form.Item name="minimum_order_cost" label="Minimum Order Cost">
          <InputNumber size="large" className="min-w-full" />
        </Form.Item>

        <Form.Item
          name="upload"
          label="Image Of Vehicle"
          valuePropName="fileList"
          accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
          getValueFromEvent={normFile}
        >
          <Upload
            name="upload"
            listType="picture-card"
            maxCount={1}
            beforeUpload={(file) => {
              return false;
            }}
          >
            {uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            span: 7,
            offset: 6,
          }}
        >
          <Button
            htmlType="submit"
            loading={load}
            className="rounded-lg w-32 h-8 border-1 border-slate-600"
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditVehicle;
