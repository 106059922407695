/*eslint-disable*/
// @author Yohannes Assebe
import api from "../adapter/base";
import { useQuery, useMutation } from "react-query";

const getSpecialOffers = async (page = 1, name) => {
  const res = await api.get(
    `product-advertisements?page=${page}${
      name ? `&product_name=${name}` : ``
    }&paginate=12`
  );
  return res?.data;
};

const deleteAdvertisement = (id) => {
  return api.delete(`/advertisements/${id}`);
};

const postSpecialOffers = (product) => {
  Object.keys(product).map((key) => {
    if (key === "startEndDate") {
      const start_date = product[key][0].format().split("T")[0];
      const end_date = product[key][1].format().split("T")[0];
      product["start_date"] = start_date;
      product["end_date"] = end_date;
      delete product[key];
      return;
    }
  });
  return api.post(`products/${product?.product_id}/advertisements`, product);
};
// fetch all SpecialOffers
export const useGetSpecialOffers = (page, name) => {
  return useQuery(["special-offers", page, name], () =>
    getSpecialOffers(page, name)
  );
};
//post advertisement
export const usePostAdvertisement = () => {
  return useMutation(postSpecialOffers);
};
//delete advertisement
export const useDeleteAd = () => {
  return useMutation(deleteAdvertisement);
};
