/* eslint-disable */
import { Form, Input, Select, Button, message } from "antd";
import { usePermissonGroups, useRegisterRole } from "../hooks/useSystemUser";
import { useQueryClient } from "react-query";
const AddRole = ({ closeAddRoleModal }) => {
  const [form] = Form.useForm();
  const { mutate: addRole, isLoading: loadAddRole } = useRegisterRole();
  const client = useQueryClient();
  const onReset = () => {
    form.resetFields();
  };
  const onFinish = (values) => {
    addRole(values, {
      onSuccess: (data) => {
        message.success(data?.data?.data?.title + " Registered");
        client.invalidateQueries("roles");
        form.resetFields();
        closeAddRoleModal();
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };
  const { data: permission_groups, isLoading: loadPermissionGroup } =
    usePermissonGroups();
  const { Option } = Select;

  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <Form.Item
        name="title"
        label="Role Name"
        rules={[{ required: true, message: "Role Name is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="permission_groups" label="Permissions">
        <Select mode="multiple" placeholder="Select Permissions">
          {permission_groups?.map((permission) => {
            return (
              <Option key={permission.id} value={permission.id}>
                {permission.title}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item>
        <div className="flex flex-row gap-6 mt-6 pl-6">
          <Button
            onClick={onReset}
            className="rounded-lg w-32 h-8 border-1 border-[#C82127] text-[#C82127]"
          >
            reset
          </Button>
          <Button
            htmlType="submit"
            loading={loadAddRole}
            className="rounded-lg w-32 h-8 border-1 border-slate-600"
          >
            + Role
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default AddRole;
