/* eslint-disable */
import { useState } from "react";
import { Table, Modal, Button, Spin, Popconfirm, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { courierTypeColumns } from "../../common/columns";
import { CiEdit } from "react-icons/ci";
import { SlTrash } from "react-icons/sl";
import { useCourierTypes } from "../../hooks/useCourierType";
import EditCourierType from "../../modals/editCourierType";
import RegisterCourierType from "../../modals/registerCourierType";
import { useDeleteCourierType } from "../../hooks/useCourierType";
import "../../assets/styles.css";
const CourierType = () => {
  const { data: courierTypes, isLoading: load_courier } = useCourierTypes();

  // variables used to add courier type modals
  const [registerModal, setRegisterModal] = useState(false);
  const toggleRegisterModal = () => setRegisterModal((visible) => !visible);
  const closeRegisterModal = () => setRegisterModal(false);
  // used to manage the modal
  const [editModal, setEditModal] = useState(false);
  const openEditModal = () => setEditModal(true);
  const closeEditModal = () => setEditModal(false);
  const [courierType, setCourierType] = useState();
  const courierTypeForOperation = (courier) => {
    setCourierType(courier);
  };
  // functions to Delete Courier Type
  const { mutation } = useDeleteCourierType();
  const deleteCourierType = (courierType) => {
    mutation.mutate(courierType.id);
  };

  const columns = [
    ...courierTypeColumns,
    {
      render: (record) => {
        return (
          <div className="flex flex-row gap-3">
            <CiEdit
              className="text-gray-500 hover:text-gray-900 hover:cursor-pointer text-base"
              onClick={() => {
                courierTypeForOperation(record);
                openEditModal();
              }}
            />
            <Popconfirm
              title="Delete Courier Type"
              description="Are you sure to delete this Courier Type?"
              onConfirm={(e) => {
                e.stopPropagation();
                deleteCourierType(record);
              }}
              onCancel={(e) => {
                e.stopPropagation();
                message.warning("ምንም ስራ አልተሰራም");
              }}
              okText="Yes"
              cancelText="No"
            >
              <SlTrash className="text-red-500 hover:text-red-900 hover:cursor-pointer text-base" />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onChange = (pagination) => {
    setPage(pagination.current);
  };

  return (
    <div className="max-w-2xl mx-auto mt-3">
      <Table
        size="small"
        dataSource={courierTypes?.data?.map((courierType) => ({
          ...courierType,
          key: courierType.id,
        }))}
        columns={columns}
        loading={{ indicator: <Spin size="small" />, spinning: load_courier }}
        onChange={onChange}
        pagination={false}
      />

      <Modal
        open={editModal}
        onCancel={closeEditModal}
        footer={null}
        closable={false}
      >
        <EditCourierType
          courierType={courierType}
          closeEditModal={closeEditModal}
        />
      </Modal>

      <Modal
        open={registerModal}
        onCancel={closeRegisterModal}
        footer={null}
        closable={false}
      >
        <RegisterCourierType closeRegisterModal={closeRegisterModal} />
      </Modal>

      <div className="add_courier_type_button_container">
        <Button
          className="add_courier_type_button"
          type="primary"
          icon={<PlusOutlined style={{ fontSize: 22, marginTop: 4 }} />}
          onClick={toggleRegisterModal}
        />
      </div>
    </div>
  );
};

export default CourierType;
