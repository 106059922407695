import api from "../adapter/base";
import { useQuery, useMutation } from "react-query";
const getCustomers = async () => {
  const response = await api.get("customers");
  const dt = await response.data;
  return dt;
};
const getUsers = () => {
  return api.get("users");
};
const updateUser = (user) => {
  return api.put(`users/${user.id}`, user.data);
};
const deleteUser = (id) => {
  api.delete(`users/${id}`);
};
const activateDeactivator = (user) => {
  if (user.is_active === 1) {
    delete user["is_active"];
    user["is_active"] = 0;
  } else {
    delete user["is_active"];
    user["is_active"] = 1;
  }
  return api.put(`users/${user?.id}`, user);
};
const searchUser = async (params, page = 1) => {
  const parseparam = (params) => {
    var param = [];
    //eslint-disable-next-line
    Object.keys(params).map((key) => {
      if (params[key] === "") return param.push(`?${key}`);
      param.push(`?${key}=${params[key]}`);
    });
    return param.join("");
  };
  const reqParam = parseparam(params.queryKey[1]);
  const response = await api.get(`customers${reqParam}&page=${page}`);
  return response?.data;
};

const customers = async (
  queryParam = "name",
  queryValue = "",
  page = 1,
  sortParam
) => {
  const dt = await api.get(
    `customers?${queryParam}=${queryValue}&page=${page}${
      sortParam ? `&sort_by=${sortParam}` : ``
    }`
  );
  return dt.data;
};

export const useAllCustomer = () => {
  return useQuery("allCustomers", getCustomers);
};

export const useGetUser = (searchText, page) => {
  return useQuery(["customer", searchText, page], () =>
    searchUser(searchText, page)
  );
};
export const useAllUsers = () => {
  return useQuery("allUsers", getUsers);
};

export const useEditUser = () => {
  return useMutation(updateUser);
};

export const useCustomers = (queryParam, queryValue, page, sortparam) => {
  return useQuery(["customers_", queryParam, queryValue, page, sortparam], () =>
    customers(queryParam, queryValue, page, sortparam)
  );
};

export const useActivateDeactivate = () => {
  return useMutation(activateDeactivator);
};

export const useDeleteCustomer = () => {
  return useMutation(deleteUser);
};
