/* eslint-disable*/
// @author:Yohannes Assebe
import {
  Card,
  Col,
  Row,
  Divider,
  Spin,
  message,
  Tooltip,
  Badge,
  Pagination,
  Input,
} from "antd";
import { isEqual } from "lodash";
import { FaTimes } from "react-icons/fa";
import { useGetSpecialOffers, useDeleteAd } from "../../hooks/useSpecialOffer";
import { useQueryClient } from "react-query";
import { useState } from "react";
import { useDebounce } from "../../hooks/useDebounce";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

const months = Array.from({ length: 12 }, (e, i) => {
  return new Date(null, i + 1, null).toLocaleDateString("en", {
    month: "short",
  });
});

const dateConvertor = (date) => {
  var date = date.split("-");
  return [months[date[1] - 1], date[2], ",", date[0]].join(" ");
};

const ActiveSpecialOffers = () => {
  const { mutate: deleteAd, isLoading: load_del } = useDeleteAd();
  const [page, setPage] = useState(1);

  const handlePageChange = (page) => {
    setPage(page);
  };

  // handle special-offer search
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedValue = useDebounce(searchTerm, 400);
  const search = (e) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  const { data: specialOffers, isLoading: load } = useGetSpecialOffers(
    page,
    debouncedValue
  );

  const client = useQueryClient();
  const deleteAdvert = (id) => {
    deleteAd(id, {
      onSuccess: () => {
        message.warning("Special Offer Removed Successfully");
        client.invalidateQueries("special-offers");
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };

  return (
    <div className="ml-[2%] w-[65%]">
      <Divider>Special Offers</Divider>
      <Input.Search
        placeholder="looking for specialOffer ?"
        className="w-[98%] px-1 py-1 rounded-3xl"
        onChange={search}
      />
      {load ? (
        <Spin size="small" className="ml-[50%] mt-[15%]" />
      ) : (
        <Row gutter={[16, 16]}>
          {specialOffers?.data?.map((advert) => {
            const discontPercentage = Math.round(
              (parseFloat(advert?.product?.discount) /
                parseFloat(advert?.product?.price)) *
                100,
              1
            );
            return (
              <Col xs={24} lg={8} key={advert?.id}>
                <Card
                  title={
                    <div className="flex flex-row justify-between">
                      {advert?.product?.name_am}
                      {advert?.is_active ? (
                        <Tooltip title="Special Offered">
                          <CheckCircleOutlined className="text-xl text-green-600" />
                        </Tooltip>
                      ) : advert?.is_pending ? (
                        <Tooltip title="pending to advert">
                          <ClockCircleOutlined className="text-xl text-sky-500" />
                        </Tooltip>
                      ) : (
                        <Tooltip title="expired Offers">
                          <CloseCircleOutlined className="text-xl text-red-600" />
                        </Tooltip>
                      )}
                    </div>
                  }
                  cover={
                    <Badge.Ribbon
                      text={[discontPercentage.toString(), "% off"].join(" ")}
                      color="rgb(22 163 74 / 1)"
                    >
                      <img
                        alt="product in the special offer"
                        src={
                          advert?.product?.image_paths
                            ? advert?.product?.image_paths[0]
                            : ``
                        }
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                        }}
                      />
                    </Badge.Ribbon>
                  }
                  actions={[
                    <span>
                      {load_del ? (
                        <Spin size="small" key={advert?.id} />
                      ) : (
                        <FaTimes
                          style={{ color: "red" }}
                          onClick={() => deleteAdvert(advert?.id)}
                        />
                      )}
                    </span>,
                  ]}
                >
                  <Card.Meta
                    title={advert?.product?.name}
                    description={
                      <div>
                        <div className="text-green-500">
                          {!isEqual(
                            parseFloat(advert?.product?.price) +
                              parseFloat(advert?.product?.discount),
                            parseFloat(advert?.product?.price)
                          ) && (
                            <strike className="text-red-500">
                              {[
                                "Price ",
                                (
                                  parseFloat(advert?.product?.price) +
                                  parseFloat(advert?.product?.discount)
                                ).toFixed(2),
                                " ብር",
                              ].join(" ")}
                            </strike>
                          )}
                          {[" ", parseFloat(advert?.product?.price), "ብር"].join(
                            " "
                          )}

                          <br />

                          {["Discount", advert?.product?.discount, "ብር"].join(
                            " "
                          )}
                        </div>
                        Available <br />
                        <span className="text-green-500">
                          {dateConvertor(advert?.start_date.split(" ")[0])}
                          {"---"}
                        </span>
                        <span className="text-red-500">
                          {dateConvertor(advert?.end_date.split(" ")[0])}{" "}
                        </span>
                      </div>
                    }
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
      )}
      <div className="mt-2">
        {specialOffers?.meta?.last_page > 1 && (
          <Pagination
            size="small"
            current={page}
            className="custom-pagination"
            total={specialOffers?.meta?.last_page * 10}
            showSizeChanger={false}
            onChange={(page) => {
              handlePageChange(page);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ActiveSpecialOffers;
