/* eslint-disable*/
// @author: Yohannes Assebe
import { useEffect, useState } from "react";
import { Button, Form, Input, Upload, Select, Divider, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useQueryClient } from "react-query";
import {
  useUpdateStoreManager,
  useAllRestaurantsWithoutPagination,
} from "../hooks/useRestaurant";
const EditRestaurantManager = ({
  restaurantManagerForOperation,
  closeEditRestaurantManagerDrawer,
}) => {
  const [form] = Form.useForm();
  //react-query client
  const client = useQueryClient();
  const { data: restaurants, isLoading: res_load } =
    useAllRestaurantsWithoutPagination();
  const { mutate: editRestaurantManager, isLoading: edit_res_load } =
    useUpdateStoreManager();
  const { Option } = Select;
  const [initialData, setInitialData] = useState();
  const onFinish = (values) => {
    const fd = new FormData();

    Object.keys(values).map((key) => {
      if (
        Object.is(values[key], undefined) ||
        Object.is(values[key], null) ||
        values[key] === ""
      ) {
        delete values[key];
        return;
      }
    });

    if (values?.password !== values.password_confirmation) {
      message.warning("password confirmation is not the same as password!");
      return;
    }
    Object.keys(values).map((key) => {
      if (key == "upload" && typeof values["upload"] != "undefined") {
        fd.append(
          "profile_image",
          values["upload"][0].originFileObj,
          values["upload"][0].originFileObj.name
        );
        delete values["upload"];
        return;
      }
      fd.append(key, values[key]);
    });
    fd.append("_method", "put");
    editRestaurantManager(
      { id: restaurantManagerForOperation.id, data: fd },
      {
        onSuccess: (data) => {
          message.success("updated Successfully");
          client.invalidateQueries("allRestaurant");
          client.invalidateQueries("allUsers");
          closeEditRestaurantManagerDrawer();
        },
        onError: (err) => {
          message.warning(err?.response?.data.message);
        },
      }
    );
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  useEffect(() => {
    setInitialData(restaurantManagerForOperation);
    form.setFieldsValue(initialData);
  }, [initialData, form, restaurantManagerForOperation]);
  return (
    <div className="-mt-5">
      <Divider>Update Restaurant Manager</Divider>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[
            {
              required: true,
              message: "Manager First Name is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[
            {
              required: true,
              message: "Manager Last Name is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="phone_number"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Manager Phone Number is required",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="2519.....(12)"
            maxLength={12}
            showCount
          />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email Account"
          rules={[
            {
              type: "email",
              required: true,
              message: "Manager Email is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="store_id"
          label="Restaurant"
          rules={[
            {
              required: true,
              message: "Managers Restaurant is required",
            },
          ]}
        >
          <Select loading={res_load}>
            {restaurants?.map((rest) => {
              return (
                <Option value={rest?.id} key={rest?.id}>
                  {rest?.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item name="password" label="Password">
          <Input size="large" type="password" />
        </Form.Item>

        <Form.Item name="password_confirmation" label="Password Confirmation">
          <Input size="large" type="password" />
        </Form.Item>
        <Form.Item
          name="upload"
          label="Image Of Manager"
          valuePropName="fileList"
          accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
          getValueFromEvent={normFile}
        >
          <Upload
            listType="picture-card"
            maxCount={1}
            beforeUpload={(file) => {
              return false;
            }}
          >
            {uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            span: 7,
            offset: 6,
          }}
        >
          <Button
            htmlType="submit"
            loading={edit_res_load}
            className="rounded-lg w-32 h-8 border-1 border-slate-600"
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditRestaurantManager;
