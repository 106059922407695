/*eslint-disable*/
import { useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Divider,
  message,
  Tag,
  Select,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useAddRestaurant } from "../hooks/useRestaurant";
import { useQueryClient } from "react-query";
// import GoogleLocationSelector from "../common/locationAutoComplete";
// import moment from "moment";
// import { TimePicker } from "antd";
import Map from "../Admin/map/map";
import { useRestaurantTypes } from "../hooks/useRestaurantType";
var fd = new FormData();
const AddRestaurant = ({ closeAddRestaurantDrawer }) => {
  const [form] = Form.useForm();
  const { mutate: registerRestaurant, isLoading: add_restaurant_load } =
    useAddRestaurant();
  const { data: restaurantTypes, isLoading: load_restaurant_types } =
    useRestaurantTypes();
  const client = useQueryClient();
  // const getPlace = (place) => {
  //   fd.append("address[latitude]", place.lat);
  //   fd.append("address[longitude]", place.lng);
  // };
  const { Option } = Select;
  const handleLocation = (data) => {
    // console.log(data);
    fd.delete("address[latitude]");
    fd.delete("address[longitude]");
    fd.append("address[latitude]", data.lat);
    fd.append("address[longitude]", data.lng);
    // fd.append("address[city]", data.city);
    // fd.append("address[sub_city]", data.subcity);
  };
  // checkable tag for days
  const { CheckableTag } = Tag;
  const daysOfWork = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [workingDays, setWorkingDays] = useState([]);

  const handleChanges = (day, checked) => {
    const nextSelectedTags = checked
      ? [...workingDays, day]
      : workingDays.filter((t) => t !== day);
    setWorkingDays(nextSelectedTags);
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = (values) => {
    Object?.keys(values)?.map((key) => {
      if (
        Object.is(values[key], undefined) ||
        Object.is(values[key], null) ||
        Object.is(values[key], " ")
      ) {
        delete values[key];
        return;
      }
    });
    Object.keys(values)?.map((key) => {
      // if (key === "work_hour_start" || key === "work_hour_end") {
      //   fd.append(
      //     key,
      //     `${moment(new Date(values[key])).hours()}:${
      //       moment(new Date(values[key])).minutes() < 10
      //         ? `0${moment(new Date(values[key])).minutes()}`
      //         : moment(new Date(values[key])).minutes()
      //     }`
      //   );
      //   delete values[key];
      //   return;
      // }

      if (key === "address") {
        Object.keys(values["address"])?.map((addressKey) => {
          fd.append(`address[${addressKey}]`, values["address"][addressKey]);
        });
        delete values["address"];
        return;
      }
      if (key === "upload" && values.upload.length) {
        values[key].map((image) => {
          fd.append("images[]", image.originFileObj, image.originFileObj.name);
        });
        delete values["upload"];
        return;
      }
      // if (key === "cover_photo" && values.upload.length) {
      //   values[key].map((image) => {
      //     fd.append(
      //       "background_images[]",
      //       image.originFileObj,
      //       image.originFileObj.name
      //     );
      //   });
      //   delete values["upload"];
      //   return;
      // }
      if (key === "store_type_ids") {
        values[key].map((store_type, index) => {
          fd.append(`store_type_ids[${index}]`, store_type);
        });
        delete values[key];
        return;
      }
      fd.append(key, values[key]);
    });

    if (workingDays?.length > 0) {
      workingDays?.map((day, index) => {
        fd.append(`work_days[${index}]`, day);
      });
    }

    console.table([...fd]);

    registerRestaurant(fd, {
      onSuccess: (data) => {
        message.success(data?.data?.data?.name + " Registered");
        client?.invalidateQueries("allRestaurant");
        form.resetFields();
        closeAddRestaurantDrawer();
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  // const coverPhoto = (e) => {
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e?.cover_photo;
  // };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <div className="-mt-5">
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Divider>Register New Restaurant</Divider>
        <Form.Item
          label="Restaurant Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Restaurant Name is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Restaurant Phone Number"
          name="phone_number"
          rules={[
            {
              required: true,
              message: "Restaurant phone number is required",
            },
          ]}
        >
          <Input size="large" maxLength={12} showCount />
        </Form.Item>
        <Form.Item label="Restaurant Type" name="store_type_ids" size="large">
          <Select mode="multiple" size="large">
            {restaurantTypes?.data?.map((resType) => {
              return (
                <Option key={resType.id} value={resType.id}>
                  {resType.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Divider>Restaurant Address</Divider>
        <Map
          google={google}
          center={{ lat: 9.02497, lng: 38.74689 }}
          height="300px"
          zoom={12}
          handleLocation={handleLocation}
        />
        <Divider>More Restaurant Address Information</Divider>
        <Form.Item
          label="City"
          name={["address", "city"]}
          rules={[
            {
              required: true,
              message: "Restaurant Address is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Sub City"
          name={["address", "sub_city"]}
          rules={[
            {
              required: true,
              message: "Restaurant Address is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Woreda"
          name={["address", "woreda"]}
          rules={[
            {
              required: true,
              message: "Restaurant Address is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Neighboorhood"
          name={["address", "neighborhood"]}
          rules={[
            {
              required: true,
              message: "Restaurant Address is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="House Number"
          name={["address", "house_number"]}
          rules={[
            {
              required: true,
              message: "Restaurant Address is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Divider>Opening/Closing Periods</Divider>
        <Form.Item label="Opening Time" name="work_hour_start">
          <input
            type="time"
            step="1"
            className="w-full py-2 px-2 rounded-md border border-gray-300 focus:outline-none focus:border-sky-600 hover:outline-none hover:border-sky-600"
          />
          {/* <TimePicker format="HH:mm" showOk={false} className="w-full" /> */}
        </Form.Item>
        <Form.Item label="Closing Time" name="work_hour_end">
          <input
            type="time"
            step="1"
            className="w-full py-2 px-2 rounded-md border border-gray-300 focus:outline-none focus:border-sky-600 hover:outline-none hover:border-sky-600"
          />

          {/* <TimePicker format="HH:mm" className="w-full" showOk={false} /> */}
        </Form.Item>
        <Form.Item label="Working Days">
          {daysOfWork?.map((day) => (
            <CheckableTag
              style={{ marginRight: 18, marginTop: 4 }}
              key={day}
              checked={workingDays?.indexOf(day) > -1}
              onChange={(checked) => handleChanges(day, checked)}
            >
              {day}
            </CheckableTag>
          ))}
        </Form.Item>
        <Divider>Upload Restaurant Images </Divider>
        <Form.Item
          name="upload"
          label="Images Of Restaurant"
          valuePropName="fileList"
          accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: "እባኮን ቢያንስ አንድ ፎቶ ይጨምሩ/this field is required",
            },
          ]}
        >
          <Upload
            name="images"
            listType="picture-card"
            beforeUpload={(file) => {
              return false;
            }}
          >
            {uploadButton}
          </Upload>
        </Form.Item>
        {/* image for the restaurant background */}
        {/* <Form.Item
          name="cover_photo"
          label="Cover Photo of restaurant (Optional)"
          valuePropName="cover_photo"
          accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
          getValueFromEvent={coverPhoto}
        >
          <Upload
            name="images"
            listType="picture-card"
            beforeUpload={(file) => {
              return false;
            }}
          >
            {uploadButton}
          </Upload>
        </Form.Item> */}
        <Form.Item>
          <div className="flex flex-row gap-6 pl-6 mt-3">
            <Button
              onClick={onReset}
              className="rounded-lg w-32 h-8 border-1 border-[#C82127] text-[#C82127]"
            >
              clear
            </Button>
            <Button
              htmlType="submit"
              className="rounded-lg w-32 h-8 border-1 border-slate-600"
              loading={add_restaurant_load}
            >
              + Restaurant
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddRestaurant;
