/*eslint-disable*/
import { Input, DatePicker, Cascader, Form } from "antd";
import { useState, useEffect } from "react";
import { useDebounce } from "../../hooks/useDebounce";
const options = [
  {
    value: "pending-pickup",
    label: "Pending PickUp",
  },
  {
    value: "pending-delivery",
    label: "Pending Delivery",
  },
  {
    value: "unpaid",
    label: "Unpaid",
  },
  {
    value: "successful",
    label: "Successful",
  },
];
const SearchBar = ({ onFiltersChange }) => {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [formChange, setFormChange] = useState();
  const debouncedValue = useDebounce(formChange, 400);

  const handleFormChange = (changedFields) => {
    const newFilters = {
      ...form.getFieldsValue(),
      ...changedFields,
    };
    setFormChange(newFilters);
  };

  useEffect(() => {
    onFiltersChange(debouncedValue);
  }, [debouncedValue]);

  return (
    <Form
      onFieldsChange={handleFormChange}
      initialValues={{}}
      form={form}
      layout="vertical"
    >
      <div className="flex flex-row space-x-1 mt-4  ml-[15%] text-center text-xs">
        <div className="flex flex-col">
          <label>Status</label>
          <Form.Item name="status">
            <Cascader
              className="text-xs"
              size="small"
              options={options}
              placeholder="Select Status"
            />
          </Form.Item>
        </div>
        <div>
          <label>Customer Name</label>
          <Form.Item name="customer_name">
            <Input
              className="text-sm"
              size="small"
              placeholder="Customer Name"
            />
          </Form.Item>
        </div>
        <div>
          <label>Order Id</label>
          <Form.Item name="order_id">
            <Input className="text-sm" size="small" placeholder="1234" />
          </Form.Item>
        </div>
        <div>
          <label>Phone Number</label>
          <Form.Item name="customer_phone_number">
            <Input
              className="text-sm"
              size="small"
              maxLength={12}
              showCount
              placeholder="251..."
            />
          </Form.Item>
        </div>

        <div className="flex flex-col">
          <label>Date Range</label>
          <Form.Item name="dateRange">
            <RangePicker className="text-sm" size="small" allowClear />
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default SearchBar;
