/* eslint-disable */
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Table, Tag, message, Spin } from "antd";
import OrderDetails from "./orderDetail";
import { fetchSearchedOrders } from "../../hooks/useOrder";
import echo from "../../wsconfig/websocket";
import { useQueryClient } from "react-query";
function SearchedOrders({ filters }) {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useQuery(["searched-orders", page, filters], () =>
    fetchSearchedOrders(filters, page)
  );
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [key, setKey] = useState(0);
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  const client = useQueryClient();
  const onRow = (record) => {
    return {
      onClick: () => {
        if (record.is_courier) {
          message.warning("No product Registered in the courier Orders");
          return;
        }
        setSelectedRowData(record);
        setKey(key + 1);
      },
    };
  };
  
  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer Name",
      dataIndex: ["user", "name"],
      key: "customerName",
    },
    {
      title: "Phone Number",
      dataIndex: ["user", "phone_number"],
      key: "phoneNumber",
    },
    {
      title: "Order Cost",
      dataIndex: "order_cost",
      key: "ordercost",
      render: (cost) => {
        return `${cost} ብር`;
      },
    },
    {
      title: "Delivery Cost",
      dataIndex: "delivery_cost",
      key: "deliverycost",
      render: (cost) => {
        return `${cost} ብር`;
      },
    },
    {
      title: "Total Cost",
      dataIndex: "total_cost",
      key: "totalcost",
      render: (cost) => {
        return `${cost} ብር`;
      },
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created at",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Payment",
      dataIndex: "payment_method",
      key: "payment",
    },
    {
      dataIndex: "is_courier",
      render: (is_courier, index) => {
        return (
          <Tag key={index} color={is_courier ? "gray" : "green"}>
            {is_courier ? "Courier" : "Normal"}
          </Tag>
        );
      },
    },
    {
      title: "Distance(KM)",
      dataIndex: ["shipping_detail", "distance_in_km"],
      render: (distance) => {
        return distance ?? "...";
      },
    },
  ];
  useEffect(() => {
    setPage(1);
  }, [filters]);

  useEffect(() => {
    echo.private("orders.paid").listen("OrderPaid", (data) => {
      console.log("event listened correctly", data);
      client.invalidateQueries(["searched-orders", 1]);
    });
    return () => {
      echo.leave("orders.status-change");
    };
  }, []);
  return (
    <>
      <Table
        columns={columns}
        dataSource={data?.data?.map((dt) => ({ ...dt, key: dt.id }))}
        pagination={{
          current: page,
          position: ["bottomCenter"],
          total: data?.meta?.last_page * 10,
          showSizeChanger: false,
          className: "custom-pagination",
        }}
        loading={{ indicator: <Spin size="small" />, spinning: isLoading }}
        onChange={handleTableChange}
        onRow={onRow}
      />
      {selectedRowData && (
        <OrderDetails
          order={selectedRowData}
          key={key}
          onClose={() => setSelectedRowData(null)}
        />
      )}
    </>
  );
}

export default SearchedOrders;
