import "mapbox-gl/dist/mapbox-gl.css";
import Map, {
  Marker,
  NavigationControl,
  FullscreenControl,
  GeolocateControl,
} from "react-map-gl";
import { Modal } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";

const MapBox = ({ visible, latitude, longitude, onClose }) => {
  return (
    <Modal open={visible} onCancel={onClose} footer={null}>
      <div style={{ height: "400px" }}>
        <Map
          mapboxAccessToken="pk.eyJ1Ijoic2VyZWdlbGEiLCJhIjoiY2t6MDZ1ZGd2MGwyaDJubzFzd2Vxbmt1aCJ9.s4bTwQbO_i47CYeUuhAk5Q"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "5px",
            border: "1px solid gray",
          }}
          initialViewState={{
            longitude: longitude,
            latitude: latitude,
            zoom: 10,
            maxZoom: 20,
            patch: 4,
          }}
          mapStyle="mapbox://styles/mapbox/streets-v11?optimize=true"
        >
          <Marker longitude={longitude} latitude={latitude}>
            <EnvironmentOutlined
              style={{ fontSize: 24, color: "#C92127", cursor: "pointer" }}
            />
          </Marker>
          <NavigationControl position="bottom-right" />
          <FullscreenControl />
          <GeolocateControl />
        </Map>
      </div>
    </Modal>
  );
};

export default MapBox;
