/* eslint-disable */
import { useEffect } from "react";
import { Form, Input, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useRegisterCourierType } from "../hooks/useCourierType";
const RegisterCourierType = ({ closeRegisterModal }) => {
  const [form] = Form.useForm();
  const { mutation } = useRegisterCourierType(closeRegisterModal);

  // register courier
  const RegisterCourier = (courier) => {
    mutation.mutate(courier);
  };

  useEffect(() => {
    form.resetFields();
  }, [mutation.isSuccess]);

  return (
    <Form form={form} onFinish={RegisterCourier} layout="vertical">
      <Form.Item
        label="Courier Type Name"
        rules={[{ required: true, message: "Courier Type Name is Required" }]}
        name="name"
      >
        <Input size="large" />
      </Form.Item>
      <div className="flex justify-end">
        <Form.Item>
          <Button
            htmlType="submit"
            icon={
              <PlusOutlined
                style={{ fontSize: 22, color: "rgba(139, 0, 0)" }}
              />
            }
            loading={mutation.isLoading}
          />
        </Form.Item>
      </div>
    </Form>
  );
};
export default RegisterCourierType;
