/* eslint-disable */
// @author : Yohannes Assebe
import {
  Button,
  Table,
  Drawer,
  Popconfirm,
  message,
  Avatar,
  Input,
} from "antd";
import { TbMotorbike } from "react-icons/tb";
import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useState } from "react";
import AddVehicle from "../../drawer/addVehicle";
import { useAllVehicles } from "../../hooks/useVehicles";
import EditVehicle from "../../drawer/editVehicle";
import { useDeleteVehicle } from "../../hooks/useVehicles";
import { useQueryClient } from "react-query";
const Vehicles = () => {
  //used to control the pagination
  const [page, setPage] = useState(1);

  //drawer controller
  const [addVehicleDrawer, setAddVehicleDrawer] = useState(false);
  const [editVehicleDrawer, setEditVehicleDrawer] = useState(false);

  //vehicle for operation like edit and delete
  const [VehicleForOperation, setVehicleForOperation] = useState();

  // searchTerm for vehicles
  const [searchTerm, setSearchTerm] = useState("");

  //mutation for delete vehicle
  const { mutate: deleteVehicle } = useDeleteVehicle();

  //query cient to invalidate query after operations
  const client = useQueryClient();

  //drawer controller functions
  const openAddVehicleDrawer = () => {
    setAddVehicleDrawer(true);
  };
  const closeAddVehicleDrawer = () => {
    setAddVehicleDrawer(false);
  };
  const openEditVehicleDrawer = () => {
    setEditVehicleDrawer(true);
  };
  const closeEditVehicleDrawer = () => {
    setEditVehicleDrawer(false);
  };

  const vehicleForOperations = (vehicle) => {
    setVehicleForOperation(vehicle);
  };
  const { data: vehicles, isLoading: load } = useAllVehicles(page);

  const filteredVehicles = vehicles?.data?.filter(
    (vehicle) =>
      vehicle?.name?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
      vehicle?.name_am?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
  );

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  const search = (e) => {
    setSearchTerm(e.target.value);
  };

  const columns = [
    {
      key: "image",
      title: "Image",
      render: (record) => {
        return <Avatar src={record?.image_path} />;
      },
    },
    {
      key: "name",
      title: "Vehicle Name",
      dataIndex: "name",
    },
    {
      key: "name_am",
      title: "Name In Amh",
      dataIndex: "name_am",
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
    },
    {
      key: "description_am",
      title: "Description In Amharic",
      dataIndex: "description_am",
    },
    {
      key: "initial_cost",
      title: "Initial Cost",
      dataIndex: "initial_cost",
      render: (initial_cost) => {
        if (initial_cost) {
          return initial_cost + " ብር";
        }
        return;
      },
    },
    {
      key: "minimum_order_cost",
      title: "Minimum Order Cost",
      dataIndex: "minimum_order_cost",
      render: (minimum_order_cost) => {
        if (minimum_order_cost) {
          return minimum_order_cost + " ብር";
        }
        return;
      },
    },
    {
      key: "id",
      render: (record) => {
        return (
          <div className="space-x-2">
            <CiEdit
              className="text-lg text-sky-600 hover:text-xl hover:cursor-pointer hover:text-sky-900"
              onClick={(e) => {
                vehicleForOperations(record);
                openEditVehicleDrawer();
              }}
            />
            <Popconfirm
              title="Delete Vehicle Type"
              description="Are you sure to delete this Vehicle Type?"
              onConfirm={(e) => {
                e.stopPropagation();
                deleteVehicle(record?.id, {
                  onSuccess: (data) => {
                    message.warning("Vehicle Type Deleted Successfully");
                    client.invalidateQueries("allVehicles");
                  },
                  onError: (error) => {
                    message.warning(error?.response?.data?.message);
                  },
                });
              }}
              onCancel={(e) => {
                e.stopPropagation();
                message.warning("ምንም ስራ አልተሰራም");
              }}
              okText="Yes"
              cancelText="No"
            >
              <MdOutlineDeleteOutline
                className="text-lg text-red-500 hover:text-xl hover:cursor-pointer hover:text-red-700"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div className="max-w-[75%] mt-3 ml-20">
      <Button onClick={openAddVehicleDrawer} className="mb-2">
        <TbMotorbike className="text-lg text-sky-700" />
        Add Vehicle
      </Button>
      <Input.Search
        className="ml-4 px-1 py-1 w-[98%]"
        placeholder="looing for vehicles?"
        onChange={search}
      />
      <Table
        className="ml-4"
        rowKey="id"
        columns={columns}
        dataSource={filteredVehicles?.map((dt) => ({ ...dt, key: dt.id }))}
        loading={load}
        pagination={{
          current: page,
          position: ["bottomCenter"],
          total: vehicles?.meta?.last_page * 10,
          showSizeChanger: false,
          className: "custom-pagination",
        }}
        onChange={handleTableChange}
      />

      <Drawer
        open={addVehicleDrawer}
        onClose={closeAddVehicleDrawer}
        closable={false}
      >
        <AddVehicle closeAddVehicleDrawer={closeAddVehicleDrawer} />
      </Drawer>
      <Drawer
        open={editVehicleDrawer}
        onClose={closeEditVehicleDrawer}
        closable={false}
      >
        <EditVehicle
          closeEditVehicleDrawer={closeEditVehicleDrawer}
          vehicleForOperations={VehicleForOperation}
        />
      </Drawer>
    </div>
  );
};

export default Vehicles;
