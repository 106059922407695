// @author : Yohannes Assebe
import { Outlet } from "react-router-dom";
import Header from "./header";
import Nav from "./nav";
const Home = () => {
  return (
    <>
      <Header />
      <Nav />
      <Outlet />
    </>
  );
};
export default Home;
