/* eslint-disable */
import { Form, Button, Input } from "antd";
import { usePostRestaurantType } from "../../hooks/useRestaurantType";
import { IoRestaurantOutline } from "react-icons/io5";
import { PlusOutlined } from "@ant-design/icons";
const AddRestaurantType = ({ closePopOver }) => {
  const [form] = Form.useForm();
  const { registerRestaurantType, isLoading: load } =
    usePostRestaurantType(closePopOver);
  const registerRestaurantTypefunc = (name) => {
    registerRestaurantType(name);
    form.resetFields();
  };
  return (
    <Form form={form} onFinish={registerRestaurantTypefunc} layout="vertical">
      <Form.Item
        label="Restuarant Type"
        name="name"
        rules={[
          { required: true, message: "Restaurant Type Name is required" },
        ]}
      >
        <Input />
      </Form.Item>
      <div className="flex justify-end">
        <Form.Item>
          <Button
            htmlType="submit"
            icon={
              <PlusOutlined
                style={{ fontSize: 22, color: "rgba(139, 0, 0)" }}
              />
            }
            loading={load}
          />
        </Form.Item>
      </div>
    </Form>
  );
};

export default AddRestaurantType;
