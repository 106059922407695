import AddSpecialOffers from "../components/addspecialOffers";
import ActiveSpecialOffers from "../components/listSpecialOffers";
const SpecialOffers = () => {
  return (
    <div className="flex flex-1 max-w-[95%] mx-auto">
      <AddSpecialOffers />
      <ActiveSpecialOffers />
    </div>
  );
};

export default SpecialOffers;
