/* eslint-disable */
import { useState } from "react";
import { Table, Spin, Input } from "antd";
import { restaurantColumn } from "../../common/columns";
import { useRestaurantsIntheirType } from "../../hooks/useRestaurantType";
import { useDebounce } from "../../hooks/useDebounce";
// component used to return table of restaurants in their type
const RestaurantInTheirType = ({ restaurantTypeId }) => {
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const term = useDebounce(searchTerm, 400);
  const { data: restaurants, isLoading: load_restaurants } =
    useRestaurantsIntheirType(restaurantTypeId, page, term);
  const handleChange = (pagination) => {
    setPage(pagination.current);
  };
  return (
    <div>
      <Input.Search
        className="justify-end"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Table
        columns={restaurantColumn}
        dataSource={restaurants?.data?.map((restaurant) => ({
          ...restaurant,
          key: restaurant.id,
        }))}
        loading={{
          indicator: <Spin size="small" />,
          spinning: load_restaurants,
        }}
        pagination={{
          current: page,
          total: restaurants?.meta?.last_page * 10,
          showSizeChanger: false,
          position: ["bottomCenter"],
          className: "custom-pagination",
        }}
        onChange={handleChange}
      />
    </div>
  );
};

export default RestaurantInTheirType;
