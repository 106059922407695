import { Avatar } from "antd";
import RegisterationDate from "./time_calculator";
export const restaurantColumn = [
  {
    title: "Restaurant",
    render: (record) => {
      return (
        <div className="flex flex-row gap-3">
          <Avatar src={record?.image_paths && record.image_paths[0]} />
          <div className="flex flex-col">
            <div className="text-gray-600">{record.name}</div>
            <span className="text-xs text-gray-500">
              registered {RegisterationDate(record.created_at)}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    title: "products",
    dataIndex: "number_of_products",
    align: "center",
  },
];

export const courierTypeColumns = [
  {
    title: "Courier Types",
    dataIndex: "name",
  },
];
