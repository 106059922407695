/*eslint-disable*/
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Upload,
  InputNumber,
  message,
  Divider,
  Rate,
} from "antd";
import { useQueryClient } from "react-query";
import { PlusOutlined } from "@ant-design/icons";
import { useAllRestaurantsWithoutPagination } from "../hooks/useRestaurant";
import { useRestaurantCategories } from "../hooks/useCategories";
import { useAddProduct } from "../hooks/useProducts";
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);

const { Option } = Select;
const AddProduct = ({ closeAddProductDrawer }) => {
  const client = useQueryClient();
  const [form] = Form.useForm();
  // variable to update restaurant categories
  const [storeId, setStoreId] = useState();

  //mutation and query data from api
  const { data: restaurants, isLoading: load_res } =
    useAllRestaurantsWithoutPagination();
  const { data: categories, isLoading: load_categories } =
    useRestaurantCategories(storeId);

  const { mutate: registerProduct, isLoading: load_add_product } =
    useAddProduct();

  const handleRestaurantChange = (value) => {
    setStoreId(value);
  };

  // reset form fields
  const onReset = () => {
    form.resetFields();
  };

  // handle register product
  const onFinish = (values) => {
    Object?.keys(values)?.map((key) => {
      if (Object.is(values[key], undefined) || Object.is(values[key], null)) {
        delete values[key];
        return;
      }
    });
    const fd = new FormData();
    Object?.keys(values)?.map((key) => {
      if (key === "upload") {
        values["upload"].map((image) => {
          fd.append("images[]", image.originFileObj, image.originFileObj.name);
        });
        delete values["upload"];
        return;
      }
      fd.append(key, values[key]);
    });

    registerProduct(fd, {
      onSuccess: (data) => {
        message.success(data?.data?.data?.name + " registered Succesfully");
        client.invalidateQueries(["allProducts", 1]);
        form.resetFields();
        closeAddProductDrawer();
      },
      onError: (error) => {
        message.warning(error?.response?.data?.message);
      },
    });
  };

  // variable and functions to search restaurant
  const [searchedRestaurant, setSearchedRestaurant] = useState("");
  const searchRestaurant = (value) => {
    setSearchedRestaurant(value);
  };

  const searchedRestaurants = restaurants?.filter(
    (restaurant) =>
      restaurant.name.toLowerCase().indexOf(searchedRestaurant.toLowerCase()) >
      -1
  );

  return (
    <div className="-mt-4">
      <Divider>
        <h4>Register New Product</h4>
      </Divider>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Product Name"
              rules={[
                {
                  required: true,
                  message: "Please Enter Product Name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item
              name="price"
              label="የእቃው ዋጋ"
              rules={[
                {
                  required: true,
                  message: "Price field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="name_am"
              label="የእቃው ስም በአማርኛ"
              rules={[
                {
                  required: true,
                  message: "እባኮን የእቃውን ስም ያስገቡ",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item
              name="discount"
              label="ዋጋው ላይ የተደረገ ቅናሽ"
              rules={[
                {
                  required: true,
                  message: "Price discount",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="description"
              label="Description In English"
              rules={[
                {
                  required: false,
                  message: "Please Enter Appropriate Description",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item
              name="store_id"
              label="Restaurant"
              rules={[
                { required: true, message: "Restaurant Field is required" },
              ]}
            >
              <Select
                onSearch={searchRestaurant}
                showSearch
                filterOption={false}
                optionFilterProp="children"
                loading={load_res}
                className="w-full"
                onChange={handleRestaurantChange}
              >
                {searchedRestaurants?.map((restaurant) => {
                  return (
                    <Option value={restaurant?.id} key={restaurant?.id}>
                      <div className="flex flex-col md:flex-row justify-between">
                        {restaurant?.name}
                        <Rate
                          className="text-xs"
                          count={5}
                          disabled
                          value={restaurant.rating}
                          key={restaurant.id}
                        />
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="description_am"
              label="ገለጻ ስለ እቃው(አማርኛ)"
              rules={[
                {
                  required: false,
                  message: "እባኮን መግለጫውን አስተካክለው ያስገቡ",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item
              name="category_id"
              label="Category"
              rules={[
                { required: true, message: "Category Field is required" },
              ]}
            >
              <Select
                loading={load_categories}
                className="w-full"
                disabled={storeId ? false : true}
              >
                {categories?.map((category) => {
                  return (
                    <Option value={category?.id} key={category?.id}>
                      {category?.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="upload"
              label="Images For Product"
              valuePropName="fileList"
              accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: "እባኮን ቢያንስ አንድ ፎቶ ይጨምሩ/this field is required",
                },
              ]}
            >
              <Upload
                name="images"
                listType="picture-card"
                beforeUpload={(file) => {
                  return false;
                }}
              >
                {uploadButton}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row className="w-[70%] ml-[15%] mt-5">
          <Col span={11} offset={1}>
            <Form.Item>
              <Button
                onClick={onReset}
                className="rounded-lg w-32 h-8 border-1 border-[#C82127] text-[#C82127]"
              >
                reset
              </Button>
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item>
              <Button
                htmlType="submit"
                loading={load_add_product}
                className="rounded-lg w-32 h-8 border-1 border-slate-600"
              >
                + Product
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default AddProduct;
