// @author Yohannes Assebe
import api from "../adapter/base";
import { useQuery, useMutation } from "react-query";
const fetchUnreadNotifications = async () => {
  const unread_notifications = await api.get("unread-notifications");
  return unread_notifications?.data?.data;
};

const fetchAllNotfications = async () => {
  const notifications = await api.get("notifications");
  return notifications?.data?.data;
};

// mark seen notifications as read
const markAsSeen = (notification_id) => {
  return api.put(`notifications/mark-as-read`, { id: notification_id });
};

// mark all notification as read
const markAllAsSeen = () => {
  return api.put(`notifications/mark-all-as-read`);
};

export const useUnreadNotifications = () => {
  return useQuery("unread_notifications", fetchUnreadNotifications);
};

export const useNotifications = () => {
  return useQuery("Notifications", fetchAllNotfications);
};

export const useMarkAsRead = () => {
  return useMutation(markAsSeen);
};

export const useMarkAllAsRead = () => {
  return useMutation(markAllAsSeen);
};
