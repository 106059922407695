/*eslint-disable*/
// @author : Yohannes Assebe
import { useState } from "react";
import { List, Avatar, Popconfirm, message, Modal } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDeleteSysUser } from "../hooks/useSystemUser";
import { useQueryClient } from "react-query";
import EditRoleUser from "../modals/editRoleUser";
const RoleUsers = ({ roleForOperation }) => {
  const { mutate: deleteUser } = useDeleteSysUser();
  const client = useQueryClient();
  // variables to control editRoleUser
  const [roleUserForOperations, setRoleUserForOperations] = useState();
  const [editRoleUserModal, setEditRoleUserModal] = useState(false);
  const openEditRoleUserModal = () => {
    setEditRoleUserModal(true);
  };
  const closeEditRoleUserModal = () => {
    setEditRoleUserModal(false);
  };
  const roleUserForOperation = (roleUser) => {
    setRoleUserForOperations(roleUser);
  };
  return (
    <>
      <List
        size="large"
        header={
          <div>
            {
              <div className="font-bold text-center text-2xl font-mono">
                {roleForOperation.title} Users List
              </div>
            }
          </div>
        }
        dataSource={roleForOperation?.users}
        renderItem={(user) => (
          <List.Item key={user.id}>
            <List.Item.Meta
              title={user?.name}
              avatar={<Avatar src={user?.profile_image_path} />}
              description={
                <div className="flex flex-row justify-between">
                  Email: {user.email}
                  <div className="text-base space-x-1">
                    <EditOutlined
                      className="text-sky-600"
                      onClick={() => {
                        openEditRoleUserModal();
                        roleUserForOperation(user);
                      }}
                    />
                    <Popconfirm
                      title="Delete User"
                      description="Are you sure to delete User?"
                      onConfirm={(e) => {
                        e.stopPropagation();
                        deleteUser(user.id, {
                          onSuccess: (data) => {
                            message.warning("User Deleted");
                            client.invalidateQueries("system-users");
                          },
                          onError: (error) => {
                            message.warning(error?.response?.data?.message);
                          },
                        });
                      }}
                      onCancel={(e) => {
                        e.stopPropagation();
                        message.warning("ምንም ስራ አልተሰራም");
                      }}
                      okText="Delete"
                      cancelText="Cancel"
                    >
                      <DeleteOutlined
                        style={{ color: "red" }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    </Popconfirm>
                  </div>
                </div>
              }
            />
          </List.Item>
        )}
      />
      <Modal
        open={editRoleUserModal}
        closable={false}
        onCancel={closeEditRoleUserModal}
        footer={null}
      >
        <EditRoleUser
          roleUserForOperation={roleUserForOperations}
          closeEditRoleUserModal={closeEditRoleUserModal}
        />
      </Modal>
    </>
  );
};
export default RoleUsers;
