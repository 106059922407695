import api from "../adapter/base";
import { useQuery } from "react-query";

//return all products counter
const productsCount = async () => {
  const res = await api.get("products/count");
  const dt = res.data;
  return dt;
};
//return all customers count
const customersCount = async () => {
  const res = await api.get("customers/count");
  const dt = res.data;
  return dt;
};
//return all categories count
const categoriesCount = async () => {
  const res = await api.get("categories/count");
  const dt = res.data;
  return dt;
};

// return all orders count
const ordersCount = async () => {
  const res = await api.get("orders/count");
  const dt = res.data;
  return dt;
};

// return all restaurants count
const restaurantsCount = async () => {
  const res = await api.get("suppliers/count");
  const dt = res.data;
  return dt;
};

// return all successfully delivered orders

const deliveredOrdersCount = async () => {
  const res = await api.get("orders/count?status=successful");
  const dt = res.data;
  return dt;
};

// react-query for best practice

export const useCustomerCount = () => {
  return useQuery("customersCount", customersCount);
};

export const useProductCount = () => {
  return useQuery("productsCount", productsCount);
};

export const useCategoryCount = () => {
  return useQuery("categoryCount", categoriesCount);
};

export const useOrderCount = () => {
  return useQuery("orderCount", ordersCount);
};

export const useRestaurantCount = () => {
  return useQuery("restaurantCount", restaurantsCount);
};

export const useDeliveredCount = () => {
  return useQuery("deliveredProductCount", deliveredOrdersCount);
};
