import { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { RxUpdate } from "react-icons/rx";
import { useUpdateRestaurantType } from "../hooks/useRestaurantType";
const EditRestaurantType = ({ restaurantType, closeEditPopOver }) => {
  const [form] = Form.useForm();
  const { updateRestaurantType, load_restaurant_type } =
    useUpdateRestaurantType(closeEditPopOver);
  const onEditRestaurantType = (restauratTypee) => {
    const restaurantTypeWithId = { ...restauratTypee, id: restaurantType?.id };
    updateRestaurantType(restaurantTypeWithId);
  };

  const [initialData, setInitialData] = useState();
  useEffect(() => {
    form.resetFields();
    setInitialData(restaurantType);
    form.setFieldsValue(initialData);
  }, [form, initialData, restaurantType]);

  return (
    <Form form={form} onFinish={onEditRestaurantType} layout="vertical">
      <Form.Item
        name="name"
        rules={[
          { required: true, message: "Restaurant Type Name is required" },
        ]}
      >
        <Input className="mt-4" size="large" />
      </Form.Item>
      <Form.Item>
        <div className="flex flex-row justify-end gap-6 pl-3 mt-2">
          <Button
            htmlType="submit"
            icon={<RxUpdate style={{ fontSize: 22, color: "rgba(139,0,0)" }} />}
            loading={load_restaurant_type}
          />
        </div>
      </Form.Item>
    </Form>
  );
};

export default EditRestaurantType;
