/*eslint-disable*/
import api from "../adapter/base";
import { useQuery, useMutation } from "react-query";

//custom hook for push notifications

//get both notifications
const getAllNotifications = async (page, searchText) => {
  const res = await api.get(
    `adverts?paginate=8&page=${page}${
      searchText ? `&search=${searchText}` : ``
    }`
  );
  return res?.data;
};
// public notification
const postAdvert = (advert) => {
  return api.post("adverts", advert);
};
// send Notificaation only for registered
const postNotifications = (notification) => {
  return api.post("notifications", notification);
};

// mark seen notifications as read
const markAsSeen = (notification_id) => {
  return api.put(`notifications/mark-as-read`, { id: notification_id });
};

// mark all notification as read
const markAllAsSeen = () => {
  return api.put(`notifications/mark-all-as-read`);
};

// react-queries
export const useAllNotifications = (page, searchText) => {
  return useQuery(["allNotifications", page, searchText], () =>
    getAllNotifications(page, searchText)
  );
};

export const usePostAdvert = () => {
  return useMutation(postAdvert);
};

export const usePostNotification = () => {
  return useMutation(postNotifications);
};

export const useMarkAsRead = () => {
  return useMutation(markAsSeen);
};

export const useMarkAllAsRead = () => {
  return useMutation(markAllAsSeen);
};
