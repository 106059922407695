/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
} from "react-google-maps";

const Map = (props) => {
  const mapRef = useRef(null);
  const autocompleteRef = useRef(null);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [subcity, setSubCity] = useState("");
  // const [state, setState] = useState("");

  // map position handler
  const [mapPosition, setMapPosition] = useState({
    lat: props.center.lat,
    lng: props.center.lng,
  });

  // marker position handler
  const [markerPosition, setMarkerPosition] = useState({
    lat: props.center.lat,
    lng: props.center.lng,
  });

  useEffect(() => {
    const map = mapRef.current;
    const autocomplete = new window.google.maps.places.Autocomplete(
      autocompleteRef.current
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) return;
      const newLat = place.geometry.location.lat();
      const newLng = place.geometry.location.lng();
      const address = place.formatted_address;
      const addressArray = place.address_components;

      const city = getCity(addressArray);
      const sub_city = getSubCity(addressArray);
      // const state = getState(addressArray);

      setAddress(address ? address : "");
      setSubCity(sub_city ? sub_city : "");
      setCity(city ? city : "");
      // setState(state ? state : "");
      setMapPosition({ lat: newLat, lng: newLng });
      setMarkerPosition({ lat: newLat, lng: newLng });
      map.setCenter({ lat: newLat, lng: newLng });
    });
  }, []);

  useEffect(() => {
    props.handleLocation({ ...mapPosition });
  }, [mapPosition]);

  useEffect(() => {
    setMapPosition({ lat: props.center.lat, lng: props.center.lng });
    setMarkerPosition({ lat: props.center.lat, lng: props.center.lng });
  }, [props]);

  const getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] === "administrative_area_level_1") {
        city = addressArray[i].long_name;

        // console.log("addressArray");
        // localStorage.setItem("addressArray", JSON.stringify(addressArray));
        // const sub_city = getSubCity(addressArray);
        // localStorage.setItem("subcity", JSON.stringify(sub_city));
        // localStorage.setItem("city", city);

        return city;
      }
    }

    // console.log("locations");
    // let citys, subCity, woreda, neighborhood;
    // for (const component of addressArray) {
    //   const types = component.types;
    //   if (types.includes("locality")) {
    //     citys = component.long_name;
    //   } else if (types.includes("sublocality")) {
    //     subCity = component.long_name;
    //   } else if (types.includes("administrative_area_level_2")) {
    //     woreda = component.long_name;
    //   } else if (types.includes("neighborhood")) {
    //     neighborhood = component.long_name;
    //   }
    // }

    // console.log("City:", citys);
    // console.log("Sub-city:", subCity);
    // console.log("Woreda:", woreda);
    // console.log("Neighborhood:", neighborhood);

    // console.log("addressArray");
    localStorage.setItem("addressArray", JSON.stringify(addressArray));
    const sub_city = getSubCity(addressArray);
    localStorage.setItem("subcity", JSON.stringify(sub_city));
    localStorage.setItem("city", city);

    return city;
  };

  const getSubCity = (addressArray) => {
    let subcity = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let j = 0; j < addressArray[i].types.length; j++) {
        if (
          addressArray[i].types[j] === "sublocality_level_1" ||
          addressArray[i].types[j] === "locality"
        ) {
          subcity = addressArray[i].long_name;
          return subcity;
        }
      }
    }
    return subcity;
  };

  const getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] === "administrative_area_level_1") {
        state = addressArray[i].long_name;
        return state;
      }
    }
    return state;
  };

  const onMarkerDragEnd = (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      { location: { lat: newLat, lng: newLng } },
      (results, status) => {
        if (status === "OK" && results[0]) {
          const address = results[0].formatted_address;
          const addressArray = results[0].address_components;
          const city = getCity(addressArray);
          const sub_city = getSubCity(addressArray);
          // const state = getState(addressArray);

          setAddress(address ? address : "");
          setSubCity(sub_city ? sub_city : "");
          setCity(city ? city : "");
          // setState(state ? state : "");
          setMapPosition({ lat: newLat, lng: newLng });
          // props.handleLocation(mapPosition);
          setMarkerPosition({ lat: newLat, lng: newLng });
        } else {
          console.error("Geocoder failed due to: ", status);
        }
      }
    );
  };

  const AsyncMap = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        ref={mapRef}
        defaultZoom={props.zoom}
        defaultCenter={{
          lat: mapPosition.lat,
          lng: mapPosition.lng,
        }}
      >
        <Marker
          name={"Dolores park"}
          draggable={true}
          onDragEnd={onMarkerDragEnd}
          position={{
            lat: markerPosition.lat,
            lng: markerPosition.lng,
          }}
        />
        {address && (
          <InfoWindow
            onClose={props.onInfoWindowClose}
            position={{
              lat: markerPosition.lat + 0.0018,
              lng: markerPosition.lng,
            }}
          >
            <div>
              <span style={{ padding: 0, margin: 0 }}>{address}</span>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    ))
  );

  return (
    <div>
      <div className="mb-11">
        <AsyncMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyDqsQE4HeZ2DS-9vWUWxpRsLs_PPBLMWtQ&libraries=places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
      <div className="space-y-4">
        <div>
          <input
            ref={autocompleteRef}
            className="py-2 px-2 w-full rounded-md border border-gray-300 focus:outline-none focus:border-sky-600 hover:outline-none hover:border-sky-600"
            type="text"
            placeholder="where is the restaurant place"
          />
        </div>

        <input
          className="py-2 px-2 w-full rounded-md border border-gray-300 focus:outline-none focus:border-sky-600 hover:outline-none hover:border-sky-600"
          type="text"
          name="address"
          placeholder="Address"
          value={address}
          disabled
        />
        <div className="flex flex-row space-x-1">
          <div className="flex flex-col space-y-2">
            <label for="lat" className="block">
              Latitude
            </label>
            <input
              id="lat"
              className="py-2 px-2 w-full rounded-md border border-gray-300 focus:outline-none focus:border-sky-600 hover:outline-none hover:border-sky-600"
              type="text"
              name="lat"
              placeholder="Latitude"
              value={mapPosition.lat}
              disabled
            />
          </div>

          <div className="flex flex-col space-y-2">
            <label for="lng">Longitude</label>
            <input
              id="lng"
              className="py-2 px-2 w-full rounded-md border border-gray-300 focus:outline-none focus:border-sky-600 hover:outline-none hover:border-sky-600"
              type="text"
              name="lng"
              placeholder="Longitude"
              value={mapPosition.lng}
              disabled
            />
          </div>
        </div>
        {/* <div>
          <label for="city">City</label>
          <input
            id="city"
            className="py-2 px-2 mt-2 w-full rounded-md border border-gray-300 focus:outline-none focus:border-sky-600 hover:outline-none hover:border-sky-600"
            type="text"
            name="city"
            placeholder="City"
            value={city}
            title="city value setted from the map"
            disabled
          />
        </div>

        <div>
          <label for="sub_city">Sub City</label>
          <input
            id="sub_city"
            className="py-2 px-2 mt-2 w-full rounded-md border border-gray-300 focus:outline-none focus:border-sky-600 hover:outline-none hover:border-sky-600"
            type="text"
            name="sub_city"
            placeholder="Sub City"
            value={subcity}
            disabled
            title="sub city value setted from the map"
          />
        </div> */}
      </div>

      {/* <div>
        <input
          className="py-2 px-2 w-full rounded-md border border-gray-300 focus:outline-none focus:border-sky-600 hover:outline-none hover:border-sky-600"
          type="text"
          name="state"
          placeholder="State"
          value={state}
          disabled
        />
      </div> */}
    </div>
  );
};

export default Map;
