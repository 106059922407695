// @author Yohannes Assebe
import Echo from "laravel-echo";
import Pusher from "pusher-js";

const pusher = new Pusher("local", {
  cluster: "mt1",
  encrypted: true,
});

const echo = new Echo({
  broadcaster: "pusher",
  key: "local",
  cluster: "mt1",
  wsHost: "socket.adikataxi.com",
  disableStats: true,
  encrypted: true,
  authEndpoint: "https://admin.adikataxi.com/api/broadcasting/auth",
  auth: {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  },
  pusher: pusher,
  forceTLS: true,

});

export default echo;
