// @author : Yohannes Assebe
import { Row, Col, Card, Divider } from "antd";
import BarChart from "../chart/barChart";
import { useNavigate } from "react-router-dom";
import {
  useCustomerCount,
  useProductCount,
  useCategoryCount,
  useOrderCount,
  useRestaurantCount,
  useDeliveredCount,
} from "../../hooks/useCounter";
const DashBoard = () => {
  //call to custom hooks for api calls
  const { data: customers, isLoading: customer_load } = useCustomerCount();
  const { data: products, isLoading: product_load } = useProductCount();
  const { data: category, isLoading: category_load } = useCategoryCount();
  const { data: order, isLoading: order_load } = useOrderCount();
  const { data: restaurant, isLoading: restaurant_load } = useRestaurantCount();
  const { data: delivered_order, isLoading: delivered_order_load } =
    useDeliveredCount();

  // data for dashboard cards
  const data = [
    {
      title: "Customers",
      counter: customers,
      loadings: customer_load,
      route: "/admin/customer_management",
    },
    {
      title: "Products",
      counter: products,
      loadings: product_load,
      route: "/admin/products",
    },
    {
      title: "Categories",
      counter: category,
      loadings: category_load,
      route: "/admin/products",
    },
    {
      title: "Orders",
      counter: order,
      loadings: order_load,
      route: "/admin/fullfillment",
    },
    {
      title: "Restaurants",
      counter: restaurant,
      loadings: restaurant_load,
      route: "/admin/restaurant",
    },
    {
      title: "Delivered Orders",
      counter: delivered_order,
      loadings: delivered_order_load,
      route: "/admin/fullfillment",
    },
  ];

  //Route Navigator
  const navigate = useNavigate();

  // handle routing
  const routeTo = (dt) => {
    if (dt.title === "Delivered Orders") {
      localStorage.setItem("tabKey", "successfully-delivered");
      navigate(dt.route);
    } else if (dt.title === "Orders") {
      localStorage.setItem("tabKey", "searched-orders");
      navigate(dt.route);
    } else {
      navigate(dt.route);
    }
  };
  return (
    <div className="ml-[12%]">
      <h6 className="text-xl mt-2 text-[#252733]">Dashboard</h6>
      <div className="ml-6">
        <Row gutter={[8, 16]} className="mt-[1%] w-[72%]">
          {data?.map((dt) => {
            return (
              <Col xs={12} md={8} xl={6} key={dt.title}>
                <Card
                  hoverable
                  className={
                    dt.title !== "Orders"
                      ? "group h-[120px] border-1 border-solid border-gray-400 hover:border-[#c82127] shadow-xl"
                      : "group h-[120px] border-1 border-solid border-[#c82127] hover:border-gray-400"
                  }
                  loading={dt.loadings}
                  onClick={() => routeTo(dt)}
                >
                  <div className="text-center text-[25px] items-center flex flex-col">
                    <div
                      className={
                        dt.title !== "Orders"
                          ? "font-bold  text-[#9FA2B4]  group-hover:text-[#c82127]"
                          : "font-bold text-[#c82127]  group-hover:text-[#9FA2B4]"
                      }
                    >
                      <h6>{dt.title}</h6>
                    </div>
                    <div
                      className={
                        dt.title !== "Orders"
                          ? "font-bold text-3xl text-black group-hover:text-[#c82127]"
                          : "font-bold text-3xl text-[#c82127] group-hover:text-black"
                      }
                    >
                      <h6>{dt.counter}</h6>
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
      <div className="max-w-[80%] mt-10">
        <Divider>
          <div>Weekly Order Report</div>
        </Divider>
        <BarChart />
      </div>
    </div>
  );
};
export default DashBoard;
