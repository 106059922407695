/* eslint-disable */
import { Rate, List, Avatar, Spin, Switch, message } from "antd";
import {
  useRestaurantRating,
  useRatingvisiblity,
} from "../hooks/useRestaurant";
import { dateConvertor } from "../common/time_calculator";
import { useQueryClient } from "react-query";
const RestaurantRating = ({ restaurantForOperation }) => {
  const { data: restaurant_rating, isLoading: load } = useRestaurantRating(
    restaurantForOperation?.id
  );

  // rate visiblity controller variables and functions
  const { mutate: changeRateVisibility, isLoading: loadVisibility } =
    useRatingvisiblity();
  const client = useQueryClient();
  const changeRatingVisiblity = (rate) => {
    changeRateVisibility(rate, {
      onSuccess: (data) => {
        data?.data?.data?.is_active
          ? message.success(
              "The rating and comment becomes visible for customers"
            )
          : message.warning(
              "The rating and comment become invisible for customers"
            );
        client.invalidateQueries([
          "restaurantRating",
          restaurantForOperation?.id,
        ]);
        client.invalidateQueries("allRestaurant");
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };

  return (
    <>
      <List
        loading={{
          indicator: <Spin size="small" />,
          spinning: load || loadVisibility,
        }}
        size="large"
        header={
          <div>
            {
              <div className="font-bold text-center text-2xl font-mono">
                {(restaurantForOperation?.name.length > 10
                  ? [
                      restaurantForOperation?.name.slice(0, 10).toUpperCase(),
                      " ...",
                    ].join(" ")
                  : restaurantForOperation?.name.toUpperCase()) + " feed backs"}
              </div>
            }
          </div>
        }
        footer={<em>@ZowiTech</em>}
        dataSource={restaurant_rating?.data}
        renderItem={(rating) => (
          <List.Item key={rating.id}>
            <List.Item.Meta
              title={
                <div className="text-bold text-md">
                  {[rating?.user?.first_name, rating?.user?.last_name].join(
                    " "
                  )}
                </div>
              }
              avatar={<Avatar src={rating?.user?.profile_image_path} />}
              description={
                <div className="flex flex-col font-mono ml-1">
                  {rating?.description && (
                    <div className="comment pl-1">
                      <div className="text-xs">{rating?.description}</div>
                    </div>
                  )}
                  <div className="flex flex-row justify-between">
                    <Rate
                      count={5}
                      value={rating?.rating}
                      disabled
                      className="text-xs"
                    />

                    <Switch
                      title="Make this comment and ratings invisible"
                      checked={rating.is_active}
                      size="small"
                      onChange={() => changeRatingVisiblity(rating)}
                    />
                  </div>
                  <div className="date text-xs pl-5">
                    commented on {dateConvertor(rating.created_at)}
                  </div>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default RestaurantRating;
