import {
  Chart,
  LinearScale,
  BarController,
  BarElement,
  CategoryScale,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";
import { Spin } from "antd";
import { Bar } from "react-chartjs-2";
import { useStatOrder } from "../../hooks/useOrder";

Chart.register(
  LinearScale,
  BarController,
  BarElement,
  CategoryScale,
  Legend,
  Title,
  Tooltip
);
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: false,
        offset: true,
      },
    },
  },
};
const BarChart = () => {
  const { data: statData, isLoading: load } = useStatOrder();
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    setChartData(statData);
  }, [statData]);

  const data = {
    labels: chartData?.map((item) => item.day).reverse(),
    datasets: [
      {
        label: "Order Cost(ብር)",
        data: chartData?.map((item) => item.order_total_cost).reverse(),
        backgroundColor: "#CD5C5C",
        borderColor: "gray",
        borderWidth: 1,
        barPercentage: 0.8,
        hoverBackgroundColor: "#F08080",
        categoryPercentage: 0.5,
        borderRadius: {
          topLeft: 30,
          topRight: 30,
          bottomLeft: 0,
          bottomRight: 0,
        },
      },
      {
        label: "Order Counts",
        data: chartData?.map((item) => item.order_count).reverse(),
        backgroundColor: "#999999",
        borderColor: "rgba(65, 162, 235, 1)",
        borderWidth: 0.7,
        barPercentage: 0.8,
        hoverBackgroundColor: "#454545",
        categoryPercentage: 0.5,
        borderRadius: {
          topLeft: 30,
          topRight: 30,
          bottomLeft: 0,
          bottomRight: 0,
        },
      },
    ],
  };
  return (
    <div>
      {!load ? (
        <Bar data={data} options={options} />
      ) : (
        <div className="ml-[50%] mt-[2%]">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default BarChart;
