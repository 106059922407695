/*eslint-disable*/
// @author : Yohannes Assebe
import { Form, Input, Divider, Button, message } from "antd";
import Adika from "../images/Adika_A.png";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../hooks/useAuth";
import { useDispatch } from "react-redux";
import { permission_action } from "../store/permission_slice";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutate: checkAuth, isLoading: load } = useLogin();
  const onFinish = (values) => {
    checkAuth(values, {
      onSuccess: (data) => {
        if (data?.data?.scopes[0] !== "administrator") {
          message.warning("Incorrect Email or Password");
          return;
        }
        sessionStorage.setItem("token", data?.data?.access_token);
        sessionStorage.setItem("user", JSON.stringify(data?.data?.data));
        dispatch(
          permission_action.registerPermission(data?.data?.permission_groups)
        );
        navigate("/admin");
      },
      onError: (error) => {
        if (error?.response?.data?.message)
          message.error(error?.response?.data?.message);
        !error?.message?.includes("Request failed with status code 401") &&
          message.error(error?.message);
      },
    });
  };
  return (
    <div className="justify-between w-2/5 mx-auto mt-[10%] h-screen">
      <div className="flex flex-row">
        <div className="ml-4 items-center h-[100%] mt-24">
          <img src={Adika} alt="adika logo" className="w-28" />
        </div>
        <div className="w-[100%] ml-5">
          <Divider>Login</Divider>
          <Form onFinish={onFinish} layout="vertical" autoComplete="off">
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Not Correct Email Format",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "password is required",
                },
              ]}
            >
              <Input type="password" size="large" />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button
                htmlType="submit"
                className="rounded-lg w-32 h-8 border-1 border-slate-600"
                loading={load}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
