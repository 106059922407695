// @author : Yohannes Assebe
import React from "react";
import Adika from "../images/Adika_A.png";
import UserImage from "../images/user.png";
import Notifications from "../Admin/components/notifications";
import { Popover, Button, Avatar } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { permission_action } from "../store/permission_slice";
import echo from "../wsconfig/websocket";
function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    echo.leave(`users.${JSON.parse(sessionStorage.getItem("user")).id}`);
    sessionStorage.clear("token");
    sessionStorage.clear("user");
    dispatch(permission_action.removePermission());
    navigate("/login");
  };
  const user = JSON.parse(sessionStorage.getItem("user"));
  return (
    <div className="flex flex-row-reverse justify-between bg-[#001529] text-white h-16">
      <div className="flex flex-row-reverse mr-6">
        <div className="flex flex-col">
          <div className="text-sm font-bold mt-3">እንኳን በደህና መጡ!</div>
          <div className="text-sm font-mono">
            {user?.name ? user?.name : ` `}
          </div>
        </div>
        <div className="w-30 h-60 rounded-lg mt-3">
          <Popover
            content={
              <div className="p-4">
                <Button
                  icon={<LogoutOutlined />}
                  size="small"
                  className="w-full"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </div>
            }
            trigger="click"
          >
            <Avatar
              className="mt-2 mr-2"
              src={
                user?.profile_image_path ? user?.profile_image_path : UserImage
              }
            />
          </Popover>
        </div>
        <div className="items-center justify-center mr-5 mt-6">
          <Notifications />
        </div>
      </div>
      <div className="w-30 h-60 rounded-lg mt-3 ml-20 flex flex-row text-center">
        <img src={Adika} alt="Adika Logo" width={40} height={40} />
        <h6 className="items-center mt-4 text-lg font-bold">
          Adika Taxi Services PLC
        </h6>
      </div>
    </div>
  );
}
export default Header;
