// @author : Yohannes Assebe
import { useQuery, useMutation } from "react-query";
import api from "../adapter/base";
import qs from "qs";
//list all restaurants
const allRestaurants = async (page = 1, restaurantName) => {
  const response = await api.get(
    `stores?products&managers&page=${page}&paginate=5${
      restaurantName ? `&name=${restaurantName}` : ``
    }`
  );
  const dt = response.data;
  return dt;
};
//list all restaurants without pagination
const restaturantsWithoutPagination = async () => {
  const restaurants = await api.get("stores?all");
  return restaurants?.data?.data;
};
// register restaurants
const addRestaurant = (restaurant) => {
  return api.post("stores", restaurant);
};
// delete restaurant
const deleteRestaurant = (id) => {
  return api.delete(`stores/${id}`);
};
// get single restaurant
const restaurant = async (id) => {
  if (id) {
    const res = await api.get(`stores/${id}`);
    return res?.data?.data;
  }
  return;
};
// used to paginate through restaurants
const restaurantPaginate = (pageNumber) => {
  return api.get("stores", {
    params: {
      page: pageNumber,
    },
    paramsSerializer: (params) => {
      qs.stringify(params, { strictNullHandling: true });
    },
  });
};

// used to update restaurant
const updateRestaurant = (restaurant) => {
  return api.post(`stores/${restaurant.id}`, restaurant.data);
};

// used to add restaturant managers
const addRestaurantManager = (manager) => {
  return api.post("store-managers", manager);
};

//used to delete restaurant manager
const deletStoreManager = (id) => {
  return api.delete(`store-managers/${id}`);
};

//used to update restaurant manager
const updateStoreManager = (manager) => {
  return api.post(`store-managers/${manager.id}`, manager.data);
};
// used to activate and deactivate restaurant
const activateDeactivateRestauraant = (restaurant) => {
  const updatedRestaurant = { is_active: !restaurant.is_active ? 1 : 0 };
  return api.put(`stores/${restaurant.id}`, updatedRestaurant);
};

// used to open and close restaurant
const openCloseRestaurant = (restaurant) => {
  const updatedRestaurant = { is_closed: !restaurant.is_closed ? 1 : 0 };
  return api.put(`stores/${restaurant.id}`, updatedRestaurant);
};

// used to fetch restaurant Rating
const restaurantRating = async (restaurant_id) => {
  const res = await api.get(`/stores/${restaurant_id}/ratings`);
  return res.data;
};

// used to make a rating invisible
const makeRatingInvisible = (rating) => {
  const ratevisibility = { is_active: rating.is_active ? 0 : 1 };
  return api.put(`/ratings/${rating.id}`, ratevisibility);
};

//react-query methods list
export const useAllRestaurants = (page, searchText) => {
  return useQuery(["allRestaurant", page, searchText], () =>
    allRestaurants(page, searchText)
  );
};

// restaurants without pagination(all restaurant lists) mostly I used for dropDowns
export const useAllRestaurantsWithoutPagination = () => {
  return useQuery(
    "restaurantsWithoutPagination",
    restaturantsWithoutPagination
  );
};

export const useAddRestaurant = () => {
  return useMutation(addRestaurant);
};

export const useRestaurant = (id) => {
  return useQuery(["restaurant", id], () => restaurant(id));
};

export const useRestaurantPagination = (pageNumber) => {
  return useQuery(["restaurant", pageNumber], restaurantPaginate(pageNumber));
};

export const useUpdateRestaurant = (onSuccess, onError) => {
  return useMutation(updateRestaurant, { onSuccess, onError });
};

export const useDeleteRestaurant = () => {
  return useMutation(deleteRestaurant);
};

export const useAddStoreManager = (onSuccess, onError) => {
  return useMutation(addRestaurantManager, { onSuccess, onError });
};

export const useDeleteStoreManager = () => {
  return useMutation(deletStoreManager);
};

export const useUpdateStoreManager = () => {
  return useMutation(updateStoreManager);
};

export const useActivateDeactivateRestaurant = () => {
  return useMutation(activateDeactivateRestauraant);
};

export const useOpenCloseRestaurant = () => {
  return useMutation(openCloseRestaurant);
};

export const useRestaurantRating = (id) => {
  return useQuery(["restaurantRating", id], () => restaurantRating(id));
};

export const useRatingvisiblity = () => {
  return useMutation(makeRatingInvisible);
};
