/*eslint-disable */
// @author: Yohannes Assebe
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpAZ, faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  Switch,
  Pagination,
  List,
  Avatar,
  Input,
  Select,
  Divider,
  Drawer,
  message,
  Spin,
  Radio,
  Popover,
} from "antd";
import { EditOutlined, MoreOutlined } from "@ant-design/icons";
import { useActivateDeactivate, useCustomers } from "../../hooks/useUser";
import { useDebounce } from "../../hooks/useDebounce";
import { useQueryClient } from "react-query";
import EditCustomer from "../../drawer/editCustomer";
import RegisterationDate from "../../common/time_calculator";
function AdminCustomerManagement() {
  const { Option } = Select;

  const [customerForOperation, setCustomerForOperation] = useState();
  // query params,value and page to fetch the data from db
  const [queryParam, setQueryParam] = useState("name");
  const [queryValue, setQueryValue] = useState("");
  const debouncedValue = useDebounce(queryValue, 400);
  const [page, setPage] = useState(1);

  // SORT FUNCTIONALITY
  // radio buttons choice
  const [sortValue, setSortValue] = useState("");
  const options = [
    {
      label: "A-Z",
      value: "first_name",
    },
    {
      label: "Registeration",
      value: "",
    },
  ];
  const onRadioChange = ({ target: { value } }) => {
    setSortValue(value);
  };
  const content = (
    <Radio.Group
      options={options}
      value={sortValue}
      onChange={onRadioChange}
      className="flex flex-col"
    />
  );

  var { data: customers, isLoading: load } = useCustomers(
    queryParam,
    debouncedValue,
    page,
    sortValue
  );

  //query client
  const client = useQueryClient();

  // drawer controller
  const [editCustomerDrawer, setEditCustomerDrawer] = useState(false);
  const { mutate: activateDeactivate, isLoading: loadActivateDeactivate } =
    useActivateDeactivate();

  //drawer controller functions
  const openEditCustomerDrawer = () => {
    setEditCustomerDrawer(true);
  };
  const closeEditCustomerDrawer = () => {
    setEditCustomerDrawer(false);
  };

  //function to send customer to edit and delete operations
  const customerForOperations = (customer) => {
    setCustomerForOperation(customer);
  };

  // handle Customer Activate and deactivate change
  const handleChange = (customer) => {
    activateDeactivate(customer, {
      onSuccess: (data) => {
        !data?.data?.data?.is_active
          ? message.warning(data?.data?.data?.name + " Deactivated")
          : message.success(data?.data?.data?.name + " Activated Successfully");
        client.invalidateQueries(["customers_", queryParam, queryValue, page]);
      },
      onError: (error) => {
        message.warning(error?.response?.data?.message);
      },
    });
  };

  return (
    <div>
      <Card className="max-h-[1100px] max-w-[70%] mt-[2.5%] ml-[15%] rounded-3xl border-[#DFE0EB]">
        <div className="flex flex-row justify-between">
          <h6 className="text-[#252733] font-bold text-lg">Customers</h6>
          <Popover
            content={content}
            trigger="click"
            placement="leftBottom"
            className="hover:cursor-pointer"
          >
            <div className="text-[#4B506D] space-x-4 mr-4">
              <span>
                <FontAwesomeIcon
                  icon={faArrowUpAZ}
                  style={{ color: "#595e79" }}
                />
                Sort
              </span>
              {/* <span>
              <FontAwesomeIcon icon={faFilter} style={{ color: "#595e79" }} />
              Filter
            </span> */}
            </div>
          </Popover>
        </div>
        <div className="w-full inline-block mt-[1.5%]">
          <span>
            <Select
              className="w-[15%]"
              defaultValue="name"
              onChange={(value) => {
                setQueryParam(value);
              }}
            >
              <Option value="name">Name</Option>
              <Option value="phone_number">Phone Number</Option>
            </Select>
            <Input.Search
              className="w-[80%]"
              placeholder="Customer Name, Phone Number or UserName"
              title="username search results may not be highlighted"
              onSearch={(value) => {
                setQueryValue(value);
                setPage(1);
              }}
              onChange={(e) => {
                setQueryValue(e.target.value);
                setPage(1);
              }}
              onKeyUp={(e) => {
                e.key === "Enter" && setQueryValue(e.target.value);
                setPage(1);
              }}
            />
          </span>
        </div>
        <div className="flex flex-row text-[#C5C7CD] text-xl mt-[1%]">
          <h6>Customer Name</h6>
          <h6 className="ml-[57%]">Edit</h6>
          <h6 className="ml-[10%]">Activate</h6>
        </div>

        <div className="bottom_border"></div>
        {
          <List
            itemLayout="horizontal"
            className="mt-[1%] w-full"
            dataSource={customers?.data}
            loading={{
              indicator: <Spin size="small" />,
              spinning: load || loadActivateDeactivate,
            }}
            renderItem={(customer) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={customer?.profile_thumbnail_path}
                      className="w-10 h-10"
                      title={
                        customer?.created_at !== customer?.updated_at
                          ? `  updated ` +
                            RegisterationDate(customer?.updated_at)
                          : `not updated`
                      }
                    />
                  }
                  title={
                    <div>
                      {customer.name
                        .toLowerCase()
                        .includes(queryValue.toLowerCase())
                        ? customer.name
                            .split(
                              new RegExp(`(${queryValue.toLowerCase()})`, "gi")
                            )
                            .map((text, i) =>
                              text.toLowerCase() ===
                              queryValue.toLowerCase() ? (
                                <span
                                  key={i}
                                  style={
                                    queryValue !== ""
                                      ? {
                                          backgroundColor: "#C12027",
                                          padding: "2px",
                                          color: "white",
                                        }
                                      : null
                                  }
                                >
                                  {text}
                                </span>
                              ) : (
                                <span key={i}>{text}</span>
                              )
                            )
                        : customer.name}
                    </div>
                  }
                  description={
                    <h6 className="text-xs text-[#C5C7CD]">
                      {`Registered ` + RegisterationDate(customer?.created_at)}
                    </h6>
                  }
                />
                <EditOutlined
                  className="text-[#C82127] text-lg pl-[3%] -ml-[46%]"
                  onClick={(e) => {
                    e.stopPropagation();
                    customerForOperations(customer);
                    openEditCustomerDrawer();
                  }}
                  title={`Edit ${customer?.name}`}
                />

                <Switch
                  className="text-sm"
                  checked={customer?.is_active}
                  onChange={() => handleChange(customer)}
                  size="small"
                  title={
                    customer?.is_active ? "activated user" : "deactivated user"
                  }
                />
                <MoreOutlined className="text-xl text-[#C5C7CD]" />
              </List.Item>
            )}
          />
        }
        <Divider />
        {customers?.meta?.last_page > 1 ? (
          <div className="ml-[25%]">
            <Pagination
              size="small"
              showSizeChanger={false}
              defaultCurrent={1}
              current={customers?.meta?.current_page}
              total={
                customers?.meta?.last_page ? customers?.meta?.last_page * 10 : 1
              }
              onChange={(page) => {
                setPage(Number(page));
              }}
              className="custom-pagination"
            />
          </div>
        ) : (
          ``
        )}
      </Card>
      <Drawer
        open={editCustomerDrawer}
        onClose={closeEditCustomerDrawer}
        closable={false}
      >
        <EditCustomer
          customerForOperations={customerForOperation}
          closeEditCustomerDrawer={closeEditCustomerDrawer}
        />
      </Drawer>
    </div>
  );
}

export default AdminCustomerManagement;
