/* eslint-disable */
import { useState } from "react";
import { useQuery } from "react-query";
import { Table, Button, Popover, Input, Form, message, Tag, Spin } from "antd";
import api from "../../adapter/base";
import OrderDetails from "./orderDetail";
import { useApproveOrder } from "../../hooks/useOrder";
import { useQueryClient } from "react-query";
// import echo from "../../wsconfig/websocket";
import { GrMapLocation } from "react-icons/gr";
import Mapbox from "../map/mapbox";
import { HiOutlineLocationMarker } from "react-icons/hi";
const fetchAcceptedOrders = async (page = 1) => {
  const response = await api.get(
    `orders?status=pending-pickup&user&page=${page}`
  );
  const data = await response.data;
  return data;
};

function AcceptedOrders() {
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const { data, isLoading } = useQuery(["accepted-orders", page], () =>
    fetchAcceptedOrders(page)
  );
  const { mutate: approveOrder, isLoading: loadApprove } = useApproveOrder();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [key, setKey] = useState(0);
  const client = useQueryClient();
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  const onRow = (record) => {
    return {
      onClick: () => {
        if (record.is_courier) {
          message.warning("No product Registered in the courier Orders");
          return;
        }
        setSelectedRowData(record);
        setKey(key + 1);
      },
    };
  };

  const handleApprove = (values, id) => {
    approveOrder(
      { orderId: id, data: { driver_phone_number: values.phone_number } },
      {
        onSuccess: () => {
          message.success("Approved Successfully");
          client.invalidateQueries(["accepted-orders", page]);
          client.invalidateQueries(["pending-delivery-orders", 1]);
          form.resetFields();
        },
        onError: (error) => {
          message.warning(error?.response?.data?.message);
          form.resetFields();
        },
      }
    );
  };

  const content = (id) => {
    return (
      <Form
        onFinish={(e) => handleApprove(e, id)}
        form={form}
        layout="vertical"
      >
        <Form.Item name="phone_number" label="Driver Phone Number (Optional) ">
          <Input
            maxLength={12}
            showCount={true}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            loading={loadApprove}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Approve
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer Name",
      dataIndex: ["user", "name"],
      key: "customerName",
    },
    {
      title: "Phone Number",
      dataIndex: ["user", "phone_number"],
      key: "phoneNumber",
    },
    {
      title: "Total Cost",
      dataIndex: "total_cost",
      key: "ordercost",
      render: (total_cost) => {
        return total_cost + " ብር";
      },
    },
    {
      title: "Order Date",
      dataIndex: "created_at",
      key: "orderDate",
    },
    {
      dataIndex: "is_courier",
      render: (is_courier, index) => {
        return (
          <Tag key={index} color={is_courier ? "gray" : "green"}>
            {is_courier ? "Courier" : "Normal"}
          </Tag>
        );
      },
    },
    {
      title: "Distance(KM)",
      dataIndex: ["shipping_detail", "distance_in_km"],
      render: (distance) => {
        return distance ?? "...";
      },
    },
    {
      title: "Location",
      render: (record) => (
        <HiOutlineLocationMarker
          className="text-[#C82127] text-lg hover:cursor-pointer"
          title="Click to view location on Map"
          aria-label="Location marker"
          onClick={(e) => {
            e.stopPropagation();
            handleLocation({
              lat: parseFloat(record.shipping_detail.latitude),
              lng: parseFloat(record.shipping_detail.longitude),
            });
          }}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "id",
      render: (orderId) => (
        <Popover content={content(orderId)} trigger="click">
          <Button
            onClick={
              (e) => e.stopPropagation()
              // handleApprove(orderId)
            }
          >
            Approve
          </Button>
        </Popover>
      ),
    },
  ];
  // useEffect(() => {
  //   echo.private(`orders.status-change`).listen("OrderApproved", (data) => {
  //     console.log("Socket Event Listened Successfully", data);
  //     client.invalidateQueries(["successfully-delivered-orders", 1]);
  //   });

  //   return () => {
  //     echo.leave(`orders.status-change`);
  //   };
  // }, []);

  const handleLocation = (location) => {
    setSelectedLocation(location);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={data?.data?.map((dt) => ({ ...dt, key: dt.id }))}
        pagination={{
          current: page,
          position: ["bottomCenter"],
          total: data?.meta?.last_page * 10,
          showSizeChanger: false,
          className: "custom-pagination",
        }}
        loading={{ indicator: <Spin size="small" />, spinning: isLoading }}
        onChange={handleTableChange}
        onRow={onRow}
      />
      {selectedRowData && (
        <OrderDetails
          order={selectedRowData}
          key={key}
          onClose={() => setSelectedRowData(null)}
        />
      )}
      {selectedLocation && modalVisible && (
        <Mapbox
          visible={modalVisible}
          latitude={selectedLocation.lat}
          longitude={selectedLocation.lng}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
}

export default AcceptedOrders;
