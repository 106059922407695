import api from "../adapter/base";
import { useQuery, useMutation } from "react-query";
const getVehicles = async (page = 1) => {
  const res = await api.get(`delivery-types?page=${page}&paginate=5`);
  const dt = res.data;
  return dt;
};

const addVehicles = (vehicle) => {
  return api.post("delivery-types", vehicle);
};

const updateVehicle = (vehicle) => {
  return api.post(`delivery-types/${vehicle.id}`, vehicle.data);
};

const deleteVehicle = (id) => {
  return api.delete(`delivery-types/${id}`);
};

export const useAllVehicles = (page) => {
  return useQuery(["allVehicles", page], () => getVehicles(page));
};

export const useAddVehicle = (onSuccess, onError) => {
  return useMutation(addVehicles, { onSuccess, onError });
};

export const useEditVehicle = (onSuccess, onError) => {
  return useMutation(updateVehicle, { onSuccess, onError });
};

export const useDeleteVehicle = () => {
  return useMutation(deleteVehicle);
};
