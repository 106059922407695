// @author: Yohannes Assebe
/*eslint-disable */
import {
  Form,
  Button,
  Divider,
  message,
  Input,
  InputNumber,
  Upload,
} from "antd";
import { useQueryClient } from "react-query";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { useAddVehicle } from "../hooks/useVehicles";
const AddVehicle = ({ closeAddVehicleDrawer }) => {
  const [form] = Form.useForm();
  //react-query client
  const client = useQueryClient();
  const onSuccess = (data) => {
    message.success(data?.data?.data?.name + " Registered Successfully");
    client.invalidateQueries("allVehicles");
    form.resetFields();
    closeAddVehicleDrawer();
  };
  const onError = (error) => {
    message.warning(error?.response?.data?.message);
  };

  //initialize mutation here with onSuccess and onError
  const { mutate: addVehicle, isLoading: load } = useAddVehicle();

  // function used to reset
  const onReset = () => {
    form.resetFields();
  };
  const onFinish = (values) => {
    Object?.keys(values)?.map((key) => {
      if (typeof values[key] == "undefined") {
        delete values[key];
        return;
      }
    });

    const fd = new FormData();
    Object?.keys(values)?.map((key) => {
      if (key === "upload" && values["upload"]) {
        fd.append(
          "image",
          values["upload"][0].originFileObj,
          values["upload"][0].originFileObj.name
        );
        delete values["upload"];
      }
      fd.append(key, values[key]);
    });
    //registeration Mutation goes here
    addVehicle(fd, { onSuccess, onError });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <div className="-mt-4">
      <Divider>
        <h3>Register New Vehicle</h3>
      </Divider>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="name"
          label="Vehicle Type Name"
          rules={[
            {
              required: true,
              message: "Vehicle Type Name is Required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="name_am"
          label="Vehicle Type Name in Amharic"
          rules={[
            {
              required: true,
              message: "Vehicle TypeName in Amharic is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Vehicle Type Description"
          rules={[
            {
              required: true,
              message: "Delivery Type Description is Required",
            },
          ]}
        >
          <TextArea maxLength={200} showCount />
        </Form.Item>
        <Form.Item
          name="description_am"
          label="Delivery Type Description in Amharic"
          rules={[
            {
              required: true,
              message: "Delivery Type Description in Amharic is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="initial_cost"
          label="Initial Cost"
          rules={[
            {
              required: true,
              message: "Initial Cost is required",
            },
          ]}
        >
          <InputNumber size="large" className="min-w-full" />
        </Form.Item>

        <Form.Item name="minimum_order_cost" label="Minimum Order Cost">
          <InputNumber size="large" className="min-w-full" />
        </Form.Item>

        <Form.Item
          name="upload"
          label="Image Of Vehicle"
          valuePropName="fileList"
          accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: "እባኮን ፎቶ ይጨምሩ/this field is required",
            },
          ]}
        >
          <Upload
            name="upload"
            listType="picture-card"
            maxCount={1}
            beforeUpload={(file) => {
              return false;
            }}
          >
            {uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item>
          <div className="flex flex-row gap-6 pl-6 mt-6">
            <Button
              onClick={onReset}
              className="rounded-lg w-32 h-8 border-1 border-[#C82127] text-[#C82127]"
            >
              reset
            </Button>
            <Button
              htmlType="submit"
              loading={load}
              className="rounded-lg w-32 h-8 border-1 border-slate-600"
            >
              + Vehicle
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddVehicle;
