// @author:Yohannes Assebe
import { useMutation, useQuery } from "react-query";
import { useQueryClient } from "react-query";
import api from "../adapter/base";
// query invalidators
export const useSystemUserQueryInvalidator = () => {
  const client = useQueryClient();
  return client.invalidateQueries("system-users");
};

export const usePermissionQueryInvalidator = () => {
  const client = useQueryClient();
  return client.invalidateQueries("permissionGroups");
};
export const useRoleQueryInvalidator = () => {
  const client = useQueryClient();
  return client.invalidateQueries("roles");
};
//fetch system users
const systemUsers = async () => {
  const res = await api.get("system-users");
  const response = res?.data?.data;
  return response;
};
// register system user
const registerSysUser = (user) => {
  return api.post("system-users", user);
};
// update system user
const updateSysUser = (user) => {
  return api.post(`system-users/${user.id}`, user.data);
};
// delete system user
const deleteSysUser = (id) => {
  return api.delete(`system-users/${id}`);
};

const getRoles = async () => {
  const res = await api.get("roles");
  return res?.data?.data;
};

export const getRole = async (id) => {
  const res = await api.get(`roles/${id}`);
  return res?.data?.data;
};

export const useGetRole = () => {
  return useQuery("roles", getRole);
};

export const getRoleWithUser = async (id) => {
  const res = await api.get(`roles/${id}?users`);
  return res?.data?.data;
};

export const useGetRoleUser = () => {
  return useQuery("roleUser", getRoleWithUser);
};

const getRolesWithUser = async () => {
  const res = await api.get(`roles?users`);
  return res.data.data;
};

export const useGetRolesWithUsers = () => {
  return useQuery("roleWithUsers", getRolesWithUser);
};

const postRole = (role) => {
  return api.post("roles", role);
};
export const useRegisterRole = () => {
  return useMutation(postRole);
};
const updateRole = (role) => {
  return api.put(`roles/${role.id}`, role.data);
};

export const useUpdateRole = () => {
  return useMutation(updateRole);
};
const deleteRole = (id) => {
  return api.delete(`roles/${id}`);
};

const permissionGroups = async () => {
  const res = await api.get("permission-groups");
  const data = res.data.data;
  const transformed_data = data?.map((dt) => ({ ...dt, key: dt.id }));
  return transformed_data;
};

const permissionGroup = async (id) => {
  const res = await api.get(`/permission-groups/${id}`);
  return res.data.data;
};

//react query
export const useSysUsers = () => {
  return useQuery("system-users", systemUsers);
};

export const useRegisterSysUser = () => {
  return useMutation(registerSysUser);
};

export const useUpdateSysUser = () => {
  return useMutation(updateSysUser);
};

export const useDeleteSysUser = () => {
  return useMutation(deleteSysUser);
};

export const useGetRoles = () => {
  return useQuery("roles", getRoles);
};

export const useDeleteRole = () => {
  return useMutation(deleteRole);
};
export const usePermissonGroups = () => {
  return useQuery("permissionGroups", permissionGroups);
};
export const usePermissionGroup = () => {
  return useQuery("permissionGroup", permissionGroup);
};
