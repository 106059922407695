import api from "../adapter/base";
import { message } from "antd";
import { useQuery, useMutation, useQueryClient } from "react-query";
const getrestaurantTypes = async () => {
  try {
    const data = await api.get(`store-types`);
    return data?.data;
  } catch (err) {
    throw err;
  }
};

export const useRestaurantTypes = () => {
  return useQuery("restaurantTypes", () => getrestaurantTypes());
};

const getRestaurant = async (id) => {
  const data = await api.get(`store-types/${id}`);
  return data?.data;
};

export const useRestaurantType = (id) => {
  return useQuery(["restaurantType", id], () => getRestaurant(id));
};

const postRestaurantType = async (restaurantType) => {
  const response = await api.post("store-types", restaurantType);
  return response;
};

export const usePostRestaurantType = (onSuccessCallback) => {
  const queryCilent = useQueryClient();
  const mutation = useMutation(postRestaurantType, {
    onSuccess: (response) => {
      const restaurantTypeName = response?.data?.data?.name;
      queryCilent.invalidateQueries("restaurantTypes");
      message.success(restaurantTypeName + " registered");
      onSuccessCallback();
    },
    onError: (error) => {
      message.warning(
        error?.response?.data?.message ?? "something goes wrong!"
      );
    },
  });
  return {
    registerRestaurantType: mutation.mutate,
    isLoading: mutation.isLoading,
  };
};

const updateRestaurantType = async (store) => {
  const response = await api.put(`store-types/${store.id}`, store);
  return response;
};

export const useUpdateRestaurantType = (onSuccessCallback) => {
  const clientQuery = useQueryClient();
  const mutation = useMutation(updateRestaurantType, {
    onSuccess: (response) => {
      const name = response?.data?.data?.name;
      message.success(name + " updated");
      clientQuery.invalidateQueries("restaurantTypes");
      onSuccessCallback();
    },
    onError: (error) => {
      const errMessage = error?.response?.data?.message;
      message.warning(errMessage ?? "Something went wrong!");
    },
  });
  return {
    updateRestaurantType: mutation.mutate,
    load_restaurant_type: mutation.isLoading,
  };
};

const deleteRestaurantType = async (id) => {
  const response = await api.delete(`/store-types/${id}`);
  return response;
};

export const useDeleteRestaurantType = () => {
  return useMutation(deleteRestaurantType, {
    onSuccess: (data) => {},
  });
};

const getRestaurantTypeRestaurants = async (id, page, term) => {
  const data = await api.get(
    `store-types/${id}/stores?page=${page}${term && `&name=${term}`}`
  );
  return data?.data;
};

export const useRestaurantsIntheirType = (id, page, term) => {
  return useQuery(["restuarantsIntheirType", id, page, term], () =>
    getRestaurantTypeRestaurants(id, page, term)
  );
};
