/*eslint-disable*/
import { Button, Form, Input, Select, Upload, message } from "antd";
import { useGetRoles, useUpdateSysUser } from "../hooks/useSystemUser";
import { PlusOutlined } from "@ant-design/icons";
import { useQueryClient } from "react-query";
import { useEffect, useState } from "react";
const EditRoleUser = ({ roleUserForOperation, closeEditRoleUserModal }) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState();
  const { data: roles } = useGetRoles();
  const { mutate: updateSystemUser, isLoading: loadUpdateSystemUser } =
    useUpdateSysUser();
  const client = useQueryClient();
  //variable and function to control the visiblity ofUpdate password fields
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const handleUpdatePasswordClick = () => {
    setShowPasswordFields((showPasswordFields) => !showPasswordFields);
  };
  //variable used to control valid or not imageformat
  const [validImgFormat, setValidImgFormat] = useState(true);
  const onFinish = (values) => {
    const fd = new FormData();
    Object.keys(values).map((key) => {
      if (
        Object.is(values[key], undefined) ||
        Object.is(values[key], null) ||
        values[key === ""]
      ) {
        delete values[key];
        return;
      }
      if (key === "upload") {
        fd.append(
          "profile_image",
          values["upload"][0].originFileObj,
          values["upload"][0].originFileObj.name
        );
        delete values["upload"];
        return;
      }

      if (key === "role") {
        fd.append("role_id", values[key].id);
        delete values["role"];
        return;
      }
      fd.append(key, values[key]);
    });
    fd.append("_method", "put");
    // console.log([...fd])
    updateSystemUser(
      { id: roleUserForOperation.id, data: fd },
      {
        onSuccess: (data) => {
          const full_name = [
            data?.data?.data?.first_name,
            data?.data?.data?.last_name,
          ].join(" ");
          client.invalidateQueries("system-users");
          client.invalidateQueries("roles");
          client.invalidateQueries("roleWithUsers");
          client.invalidateQueries("roleUser");
          message.success(full_name + " Updated");
          closeEditRoleUserModal();
        },
        onError: (err) => {
          console.log("Wrong Input");
          message.warning(err?.response?.data?.message);
        },
      }
    );
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  useEffect(() => {
    form.resetFields();
    setInitialValues(roleUserForOperation);
    form.setFieldsValue(initialValues);
  }, [form, initialValues, roleUserForOperation]);

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Form.Item
        label="User Name"
        name="user_name"
        rules={[{ required: true, message: "this field is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="First Name"
        name="first_name"
        rules={[{ required: true, message: "this field is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="last_name"
        rules={[{ required: true, message: "this field is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Phone Number"
        name="phone_number"
        rules={[{ required: true, message: "this field is required" }]}
      >
        <Input maxLength={12} showCount />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, type: "email", message: "this field is required" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Role"
        name={["role", "id"]}
        rules={[{ required: true, message: "this field is required" }]}
      >
        <Select>
          {roles?.map((role) => {
            return (
              <Option key={role.id} value={role.id}>
                {role.title}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Button
        onClick={handleUpdatePasswordClick}
        className="border-1 border-slate-800 mb-4 hover:text-slate-50"
        size="small"
      >
        {showPasswordFields ? "Hide Password Fields" : "Update Password"}
      </Button>
      {showPasswordFields && (
        <div className="ml-5">
          <Form.Item
            label="password"
            name="password"
            rules={[{ min: 8, message: "pasword must be greater than 8" }]}
          >
            <Input type="password" placeholder="Optional" autoComplete="off" />
          </Form.Item>
          <Form.Item
            label="Password Confirmation"
            name="password_confirmation"
            rules={[
              {
                min: 8,
                message: "password confirmation must be greater than 8 too",
              },
            ]}
          >
            <Input type="password" placeholder="Optional" autoComplete="off" />
          </Form.Item>
        </div>
      )}

      <Form.Item
        name="upload"
        label="Image"
        valuePropName="fileList"
        accept=".jpg, .jpeg, .png, .gif"
        getValueFromEvent={normFile}
      >
        <Upload
          listType="picture-card"
          maxCount={1}
          beforeUpload={(file) => {
            const imgFormats = [
              "image/jpeg",
              "image/png",
              "image/jpg",
              "image/gif",
            ];
            const isValid = imgFormats.find((format) => file.type === format);
            if (!isValid) {
              setValidImgFormat(false);
            } else {
              setValidImgFormat(true);
            }
            return false;
          }}
        >
          {uploadButton}
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          loading={loadUpdateSystemUser}
          disabled={!validImgFormat}
          className="rounded-lg w-32 h-8 border-1 border-slate-600"
        >
          {validImgFormat
            ? `Update`
            : `change Image only .jpg, .jpeg, .png, .gif are valid`}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditRoleUser;
