// @author : Yohannes Assebe
/*eslint-disable*/
import { useState } from "react";
import { Tabs } from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  SearchOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import SearchBar from "../components/searchBars";
import SearchedOrders from "../components/searchedOrders";
// import AcceptPendingPickup from "../components/pendingPickup";
import AcceptedOrders from "../components/acceptedOrders";
import PendingDelivery from "../components/pendingDelivery";
import SuccessfullyDelivered from "../components/successfullyDelivered";
const Fullfillment = () => {
  const [filters, setFilters] = useState();
  const [page] = useState(1);

  // variable to control the default selected key for fullfillment tabs
  const [defaultKey, setDefaultKey] = useState(
    localStorage.getItem("tabKey") || "searched-orders"
  );

  const changeDefaultKey = (key) => {
    localStorage.setItem("tabKey", key);
    setDefaultKey(localStorage.getItem("tabKey"));
  };

  const tabItems = [
    {
      key: "searched-orders",
      label: (
        <span>
          <SearchOutlined />
          Searched Results
        </span>
      ),
      children: <SearchedOrders filters={filters} pageToDefault={page} />,
    },
    {
      key: "accepted-order",
      label: (
        <span>
          <CheckCircleOutlined />
          Accept Order
        </span>
      ),
      children: <AcceptedOrders />,
    },
    {
      key: "pending-delivery",
      label: (
        <span>
          <ClockCircleOutlined />
          Pending Delivery
        </span>
      ),
      children: <PendingDelivery />,
    },
    {
      key: "successfully-delivered",
      label: (
        <span>
          <SmileOutlined />
          Successfully Delivered
        </span>
      ),
      children: <SuccessfullyDelivered />,
    },
  ];
  const handleFiltersChange = (newFilter) => {
    setFilters(newFilter);
    newFilter && setDefaultKey("searched-orders");
  };

  return (
    <>
      <SearchBar onFiltersChange={handleFiltersChange} />
      <div className="flex justify-center">
        <Tabs
          items={tabItems}
          defaultActiveKey={defaultKey}
          activeKey={defaultKey}
          onChange={(key) => changeDefaultKey(key)}
        />
      </div>
    </>
  );
};

export default Fullfillment;
