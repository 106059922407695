/* eslint-disable */
import { Avatar, Divider, Modal, Table } from "antd";
import { useState } from "react";
function OrderDetails({ order }) {
  const [visible, setVisible] = useState(true);
  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image_paths",
      render: (image) => {
        return <Avatar src={<img src={image[0]} alt="Product" />} />;
      },
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "product",
    },
    {
      title: "Quantity",
      dataIndex: ["pivot", "quantity"],
      key: "quantity",
    },
    {
      title: "Price",
      dataIndex: ["pivot", "price"],
      key: "price",
      render: (price) => <span>{`${price} ብር`}</span>,
    },
    {
      title: "Discount",
      dataIndex: ["pivot", "discount"],
      key: "discount",
      render: (discount) => <span>{`${discount} ብር`}</span>,
    },
    {
      title: "Total   ",
      key: "discount",
      render: (text, record) => (
        <span>{`${
          (parseFloat(record?.pivot?.price) -
            parseFloat(record?.pivot?.discount)) *
          parseFloat(record?.pivot?.quantity)
        } ብር`}</span>
      ),
    },
  ];

  // used for tatable summary

  const getSummaryRow = () => {
    return (
      <Table.Summary.Row>
        <Table.Summary.Cell />
        <Table.Summary.Cell />
        <Table.Summary.Cell />
        <Table.Summary.Cell />
        <Table.Summary.Cell className="text-xs font-bold">
          ማድረሻ(ብር)
        </Table.Summary.Cell>
        <Table.Summary.Cell className="text-xs font-bold text-red-700">
          {order?.delivery_cost.toFixed(2)}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  const foolter = (
    <div className="grid grid-cols-6 gap-1">
      <div />
      <div />
      <div />
      <div />
      <div className="text-base font-bold -ml-5">የሁሉም ድምር</div>
      <div className="text-base font-bold w-full text-red-700">
        {order?.total_cost.toFixed(2)} ብር
      </div>
      {/* <div /> */}
    </div>
  );

  return (
    <>
      <Modal
        title={`Order #${order.id} Products Details`}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={foolter}
      >
        <Table
          dataSource={order?.products?.map((product) => ({
            ...product,
            key: product.id,
          }))}
          columns={columns}
          pagination={false}
          rowKey="key"
          style={{ marginBottom: "40px" }}
          summary={getSummaryRow}
        />
      </Modal>
    </>
  );
}

export default OrderDetails;
