/*eslint-disable*/
import { Button, Form, Input, Select, Upload, message } from "antd";
import { useGetRoles, useRegisterSysUser } from "../hooks/useSystemUser";
import { PlusOutlined } from "@ant-design/icons";
import { useQueryClient } from "react-query";
import { useState } from "react";
const AddRoleUser = ({ closeAddRoleUserModal }) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const { data: roles } = useGetRoles();
  const { mutate: registerSysUser, isLoading: loadRegisterSysUser } =
    useRegisterSysUser();
  const client = useQueryClient();
  //variable used to control valid or not imageformat
  const [validImgFormat, setValidImgFormat] = useState(true);
  const onFinish = (values) => {
    const fd = new FormData();
    Object.keys(values).map((key) => {
      if (key === "upload" && typeof values[key] !== "undefined") {
        fd.append(
          "profile_image",
          values["upload"][0].originFileObj,
          values["upload"][0].originFileObj.name
        );
        delete values["upload"];
        return;
      }
      fd.append(key, values[key]);
    });
    //function to handle reset input fields
    const onReset = () => {
      form.resetFields();
    };
    // function to register system users
    registerSysUser(fd, {
      onSuccess: (data) => {
        const full_name = [
          data?.data?.data?.first_name,
          data?.data?.data?.last_name,
        ].join(" ");
        client.invalidateQueries("system-users");
        client.invalidateQueries("roles");
        client.invalidateQueries("roleWithUsers");
        client.invalidateQueries("roleUser");
        message.success(full_name + " registered");
        closeAddRoleUserModal();
      },
      onError: (err) => {
        console.log("Wrong Input");
        message.warning(err?.response?.data?.message);
      },
    });
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <Form onFinish={onFinish} form={form} layout="vertical" autoComplete="off">
      <Form.Item
        label="User Name"
        name="user_name"
        rules={[{ required: true, message: "this field is required" }]}
      >
        <Input autoComplete="new-userName" />
      </Form.Item>
      <Form.Item
        label="First Name"
        name="first_name"
        rules={[{ required: true, message: "this field is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="last_name"
        rules={[{ required: true, message: "this field is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Phone Number"
        name="phone_number"
        rules={[{ required: true, message: "this field is required" }]}
      >
        <Input maxLength={12} showCount />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, type: "email", message: "this field is required" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="password"
        name="password"
        rules={[
          {
            required: true,
            message: "this field is required",
          },
          { min: 8, message: "pasword must be greater than 8" },
        ]}
      >
        <Input type="password" />
      </Form.Item>
      <Form.Item
        label="Password Confirmation"
        name="password_confirmation"
        rules={[
          {
            required: true,
            message: "this field is required",
          },
          {
            min: 8,
            message: "password confirmation must be greater than 8 too",
          },
        ]}
      >
        <Input type="password" />
      </Form.Item>
      <Form.Item
        label="Role"
        name="role_id"
        rules={[{ required: true, message: "this field is required" }]}
      >
        <Select>
          {roles?.map((role) => {
            return (
              <Option key={role.id} value={role.id}>
                {role.title}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name="upload"
        label="Image"
        valuePropName="fileList"
        accept=".jpg, .jpeg, .png, .gif"
        getValueFromEvent={normFile}
      >
        <Upload
          listType="picture-card"
          maxCount={1}
          beforeUpload={(file) => {
            const imgFormats = [
              "image/jpeg",
              "image/png",
              "image/jpg",
              "image/gif",
            ];
            const isValid = imgFormats.find((format) => file.type === format);
            if (!isValid) {
              setValidImgFormat(false);
            } else {
              setValidImgFormat(true);
            }
            return false;
          }}
        >
          {uploadButton}
        </Upload>
      </Form.Item>
      <Form.Item>
        <div className="flex flex-row gap-6 pl-6 mt-6">
          <Button
            onClick={onReset}
            className="rounded-lg w-32 h-8 border-1 border-[#C82127] text-[#C82127]"
          >
            reset
          </Button>
          <Button
            htmlType="submit"
            loading={loadRegisterSysUser}
            disabled={!validImgFormat}
            className="rounded-lg w-32 h-8 border-1 border-slate-600"
          >
            {validImgFormat
              ? `+ Register`
              : `change Image only .jpg, .jpeg, .png, .gif are valid`}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default AddRoleUser;
