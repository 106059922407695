// @author : Yohannes Assebe
import { useState } from "react";
import {
  Col,
  Row,
  Table,
  Button,
  Divider,
  Form,
  Input,
  Radio,
  message,
  Spin,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {
  useAllNotifications,
  usePostAdvert,
  usePostNotification,
} from "../../hooks/useNotification";
import { useQueryClient } from "react-query";
import { useDebounce } from "../../hooks/useDebounce";
const PushNotification = () => {
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedValue = useDebounce(searchTerm, 400);
  const [page, setPage] = useState(1);
  const { data: notifications, isLoading: loadNotification } =
    useAllNotifications(page, debouncedValue);
  const { mutate: postPublicNotification, isLoading: loadPostAdvert } =
    usePostAdvert();
  const { mutate: postNotification, isLoading: loadPostNotifcation } =
    usePostNotification();
  const client = useQueryClient();
  const handleSearch = (term) => {
    setSearchTerm(term.target.value);
    setPage(1);
  };
  const handlePageChange = (page) => {
    setPage(page);
  };
  // handle reset
  const onReset = () => {
    form.resetFields();
  };

  // function to push notification
  const onFinish = (values) => {
    if (values?.audience === "all_users") {
      postPublicNotification(values, {
        onSuccess: () => {
          message.success("Notification sent Successfully!");
          form.resetFields();
          client.invalidateQueries("allNotifications");
        },
        onError: (err) => {
          message.warning(err?.response?.data?.message);
        },
      });
    } else {
      postNotification(values, {
        onSuccess: () => {
          message.success("Notification sent Successfully!");
          client.invalidateQueries("allNotifications");
        },
        onError: (err) => {
          message.warning(err?.response?.data?.message);
        },
      });
    }
  };
  const onFinishFailed = (error) => {
    console.warn("Error Happened");
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Sent To",
      dataIndex: "is_notification",
      key: "sent_to",
      render: (isNotification) => {
        if (isNotification) {
          return <div className="text-[#C92127]">only Registered</div>;
        } else return <div className="text-green-700">All Users</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "date",
      render: (created_at) => {
        return created_at.split("T")[0];
      },
    },
  ];

  return (
    <div className="max-w-[90%] mt-1 ml-8">
      <Row>
        <Col span={12}>
          <Divider>Send Notification</Divider>
          <Form
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 12,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
            autoComplete="off"
          >
            <Form.Item
              name="audience"
              label="Audience"
              rules={[
                {
                  required: true,
                  message: "Please Choice Your Audiences",
                },
              ]}
            >
              <Radio.Group style={{ width: "100%" }}>
                <Row>
                  <Col span={12}>
                    <Radio value="all_users">All users</Radio>
                  </Col>
                  <Col span={12}>
                    <Radio value="registered_users">Only Registered</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input title!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Message"
              name="message"
              rules={[
                {
                  required: true,
                  message: "Please input Message!",
                },
              ]}
            >
              <TextArea />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <div className="flex flex-row gap-6 ">
                <Button
                  onClick={onReset}
                  className="rounded-lg text-sm border-1 border-[#C92127] text-[#C92127]"
                >
                  reset
                </Button>
                <Button
                  htmlType="submit"
                  loading={loadPostAdvert || loadPostNotifcation}
                  className="rounded-lg text-sm border-1 border-slate-600"
                >
                  send
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Col>
        <Col span={12}>
          <Divider>All Pushed Notification</Divider>
          <div className="container">
            <Input.Search
              placeholder="Search Message"
              onChange={handleSearch}
            />
            <Table
              size="small"
              dataSource={notifications?.data?.map((notification, index) => ({
                ...notification,
                key: [notification.id, index, notification.title].join(" "),
              }))}
              loading={{
                indicator: <Spin size="small" />,
                spinning: loadNotification,
              }}
              columns={columns}
              className="custom-pagination"
              pagination={{
                current: page,
                position: ["bottomCenter"],
                total: notifications?.meta?.last_page * 10,
                showSizeChanger: false,
                onChange: (page) => {
                  handlePageChange(page);
                },
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PushNotification;
