// @author : Yohannes Assebe
import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
function Nav(props) {
  const permissions = useSelector((state) => state.permission.permission);
  return (
    <>
      <div>
        <Menu
          mode="horizontal"
          defaultSelectedKeys={props.nav_link}
          overflowedIndicator={<MenuOutlined />}
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
          className="font-semibold w-[97%] ml-[1%]"
        >
          {permissions.includes("Dashboard") && (
            <Menu.Item key="main_page">
              <Link
                to="dashboard"
                className="text-lg text-center font-bold items-center "
              >
                ዋና ገጽ
              </Link>
            </Menu.Item>
          )}

          {permissions.includes("Product Management") && (
            <Menu.Item key="products">
              <Link to="products" className="border-1 border-r-red-900">
                Products
              </Link>
            </Menu.Item>
          )}
          {permissions.includes("Store Management") && (
            <Menu.Item key="restaurant">
              <Link to="restaurant">Restaurant</Link>
            </Menu.Item>
          )}
          {permissions.includes("Delivery Type Management") && (
            <Menu.Item key="vahicle_types">
              <Link to="vehicles">Vehicle Type</Link>
            </Menu.Item>
          )}
          {permissions.includes("RBAC Management") && (
            <Menu.Item key="rbac">
              <Link to="rbac">RBAC</Link>
            </Menu.Item>
          )}
          {permissions.includes("Fulfillment Management") && (
            <Menu.Item key="fullfillment">
              <Link to="fullfillment">Fullfillment</Link>
            </Menu.Item>
          )}
          {permissions.includes("Customer Management") && (
            <Menu.Item key="customer_management">
              <Link to="customer_management">Customer management</Link>
            </Menu.Item>
          )}
          {permissions.includes("Notification Management") && (
            <Menu.Item key="push_notification">
              <Link to="push_notification">Push Notification</Link>
            </Menu.Item>
          )}
          {permissions.includes("Setting Management") && (
            <Menu.Item key="site_settings">
              <Link to="site_settings">Site Settings</Link>
            </Menu.Item>
          )}
          {permissions.includes("Advertisement Management") && (
            <Menu.Item key="special_offers">
              <Link to="special_offers">Special Offers</Link>
            </Menu.Item>
          )}
          {permissions.includes("Report Management") && (
            <Menu.Item key="reports">
              <Link to="report">Reports</Link>
            </Menu.Item>
          )}
          {permissions.includes("Store Management") && (
            <Menu.Item key="restaurant_type">
              <Link to="restaurant_type">Restaurant Type</Link>
            </Menu.Item>
          )}
          {permissions.includes("Setting Management") && (
            <Menu.Item key="courier_type">
              <Link to="courier_type">Courier Type</Link>
            </Menu.Item>
          )}
        </Menu>
      </div>
    </>
  );
}

export default Nav;
