// @author : Yohannes Assebe
import { useMutation } from "react-query";
import api from "../adapter/base";
const login = (credentials) => {
  return api.post("/login", credentials);
};

export const useLogin = () => {
  return useMutation(login);
};
