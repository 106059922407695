import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
function NotFoundPage() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link to="/">
            <Button>Back Home</Button>
          </Link>
        }
      />
    </div>
  );
}

export default NotFoundPage;
