/*eslint-disable*/
import { createSlice } from "@reduxjs/toolkit";
const permission = createSlice({
  name: "permission",
  initialState: {
    permission: [],
  },
  reducers: {
    registerPermission(state, action) {
      //   console.log(action.payload);
      state.permission = [];
      Object.keys(action.payload)?.map((permission) => {
        const per = parseInt(permission);
        state.permission.push(action.payload[per]["title"]);
      });
      //   console.log(state.permission);
    },
    removePermission(state) {
      state.permission = [];
    },
  },
});

export const permission_action = permission.actions;
export default permission;
