/*eslint-disable*/
// @author : Yohannes Assebe
import { useState } from "react";
import {
  Button,
  Spin,
  Divider,
  Card,
  Image,
  Carousel,
  Drawer,
  Popconfirm,
  message,
  Pagination,
  Select,
  Switch,
  Breadcrumb,
  Input,
  Popover,
  Rate,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDeleteCategory, useCategory } from "../../hooks/useCategories";
import { IoFastFoodOutline } from "react-icons/io5";
import { BiCategoryAlt } from "react-icons/bi";
import {
  IoIosAddCircleOutline,
  IoIosRemoveCircleOutline,
} from "react-icons/io";

import {
  useDeleteProduct,
  useActivateDeactivateProduct,
} from "../../hooks/useProducts";
import {
  useRestaurant,
  useAllRestaurantsWithoutPagination,
} from "../../hooks/useRestaurant";

import { useQueryClient } from "react-query";
import api from "../../adapter/base";
import { useQuery } from "react-query";
import NoImage from "../../images/No-Image.png";
import AddProduct from "../../drawer/addProduct";
import AddCategory from "../../drawer/addCategory";
import EditProduct from "../../drawer/editProduct";
import EditCategory from "../../drawer/editCategory";
import NoDataResult from "../../common/Nodata";
import { useDebounce } from "../../hooks/useDebounce";

const { Meta } = Card;

const fetchProducts = async (page = 1, category_id, store_id, searchTerm) => {
  const response = await api.get(
    `products?page=${page}&paginate=12${
      category_id ? `&category_id=${category_id}` : ``
    }${store_id ? `&store_id=${store_id}` : ``}${
      searchTerm ? `&name=${searchTerm}` : ``
    }`
  );
  const data = await response.data;
  return data;
};

const fetchCategories = async (store_id) => {
  const response = await api.get(
    `categories?all${store_id ? `&store_id=${store_id}` : ``}`
  );
  const data = await response.data.data;
  return data;
};

const { Option } = Select;

const Products = () => {
  // product and category for operations like edit and delete
  const [productForOperation, setProductForOperation] = useState();
  const [categoryForOperation, setCategoryForOperation] = useState();

  //variables used to manage pagination and category change
  const [page, setPage] = useState(1);
  const [categoryChange, setCategoryChange] = useState(null);
  const [storeId, setStoreId] = useState(null);

  //variables for drawer controllers
  const [addProductDrawer, setAddProductDrawer] = useState(false);
  const [addCategoryDrawer, setAddCategoryDrawer] = useState(false);
  const [editProductDrawer, setEditProductDrawer] = useState(false);
  const [editCategoryDrawer, setEditCategoryDrawer] = useState(false);

  //mutation to update the status of product make it visible/invisible to clients
  const { mutate: activateDeactivateProduct, isLoading: load_update_product } =
    useActivateDeactivateProduct();

  // controllers for popovers
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  // drawer controller functions
  // function to manage add product drawer
  const openAddProductDrawer = () => {
    setAddProductDrawer(true);
    hide();
  };
  const closeAddProductDrawer = () => {
    setAddProductDrawer(false);
  };
  //function to manage add category drawer
  const openAddCategoryDrawer = () => {
    setAddCategoryDrawer(true);
    hide();
  };
  const closeAddCategoryDrawer = () => {
    setAddCategoryDrawer(false);
  };
  //function to manage edit product drawer
  const openEditProductDrawer = () => {
    setEditProductDrawer(true);
  };
  const closeEditProductDrawer = () => {
    setEditProductDrawer(false);
  };
  //function to manage edit category drawer
  const openEditCategoryDrawer = () => {
    setEditCategoryDrawer(true);
  };
  const closeEditCategoryDrawer = () => {
    setEditCategoryDrawer(false);
  };

  //functions to set product and category for operation
  const productForOperations = (product) => {
    setProductForOperation(product);
  };

  const categoryForOperations = (category) => {
    setCategoryForOperation(category);
  };

  const handleActivateDeactivateProductVisiblity = (product) => {
    activateDeactivateProduct(product, {
      onSuccess: (data) => {
        const name = data?.data?.data?.name;
        const status = data?.data?.data?.is_active
          ? "Activated"
          : "Deactivated";
        if (status === "Activated") {
          message.success(`${name} ${status} Successfully`);
        } else {
          message.warning(`${name} ${status}`);
        }
        client.invalidateQueries([
          "allProducts",
          page,
          categoryChange,
          storeId,
        ]);
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };

  //varaibles for custom hook handles
  const client = useQueryClient();
  const { mutate: deleteProduct } = useDeleteProduct();
  const { mutate: deleteCategory } = useDeleteCategory();

  // handle product search
  const [searchText, setSearchText] = useState("");
  const search = (searchTerm) => {
    setSearchText(searchTerm);
    setPage(1);
    setStoreId(null);
    setCategoryChange(null);
  };
  const debouncedValue = useDebounce(searchText, 400);
  const { data, isLoading: load_product } = useQuery(
    ["allProducts", page, categoryChange, storeId, debouncedValue],
    () => fetchProducts(page, categoryChange, storeId, debouncedValue)
  );

  const { data: categories, isLoading: categoryLoad } = useQuery(
    ["category", storeId],
    () => fetchCategories(storeId)
  );

  const { data: allRestaurants, isLoading: load_restaurants } =
    useAllRestaurantsWithoutPagination();

  // variables and functions to search restaurant from selectbox
  const [restaurantSearchTerm, setRestaurantSearchTerm] = useState("");
  const searchedRestaurant = allRestaurants?.filter(
    (restaurant) =>
      restaurant.name
        .toLowerCase()
        .indexOf(restaurantSearchTerm.toLowerCase()) > -1
  );

  const handleRestaurantSearch = (value) => {
    setRestaurantSearchTerm(value);
  };

  // fetch restaurant and category for breadcrumb
  const { data: restaurant } = useRestaurant(storeId);
  const { data: category } = useCategory(categoryChange);

  // handle category and page changes

  const handleCategoryChange = (id) => {
    setPage(1);
    setCategoryChange(id);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleStoreChange = (value) => {
    setStoreId(value);
    setCategoryChange(null);
    setPage(1);
  };

  const buttons = (
    <div className="flex flex-col">
      <Button onClick={openAddProductDrawer} className="px-1 py-1 border-none">
        <div className="flex flex-row gap-1">
          <IoFastFoodOutline className="text-lg text-[#c82127]" />+ Product
        </div>
      </Button>
      <Button onClick={openAddCategoryDrawer} className="px-1 py-1 border-none">
        <div className="flex gap-1">
          <BiCategoryAlt className="text-lg text-[#c82127]" />+ Category
        </div>
      </Button>
    </div>
  );

  return (
    <div className="flex flex-row space-x-2">
      <div className="border-solid border-x-2 border-0 border-gray-100  w-fit ml-3 mt-4">
        <Divider>
          <h4 className="text-2xl font-extralight">Categories</h4>
        </Divider>
        <div className="space-y-3">
          {categoryLoad ? (
            <Spin size="small" className="ml-[50%] mt-[50%]" />
          ) : categories?.length == 0 ? (
            <NoDataResult name="Category" />
          ) : (
            <div>
              <div
                className="border-solid border-b-2 border-0 border-b-gray-800 w-full px-2 py-2 rounded-lg text-center text-sm hover:border-[#c82127] hover:text-[#c82127]"
                onClick={() => handleCategoryChange(null)}
              >
                All Products
              </div>
              {categories?.map((category) => {
                return (
                  <div key={category?.id}>
                    <div
                      className="text-sm border-solid border-b-2 border-0 border-b-gray-800 w-auto px-2 py-2 rounded-lg text-center hover:border-[#c82127] hover:text-[#c82127]"
                      onClick={() => {
                        handleCategoryChange(category?.id);
                      }}
                    >
                      {category?.name}
                      <EditOutlined
                        className="text-green-600 text-xs hover:text-base"
                        onClick={(e) => {
                          e.stopPropagation();
                          categoryForOperations(category);
                          openEditCategoryDrawer();
                        }}
                      />
                      <Popconfirm
                        title="Delete Category"
                        description="Are you sure to delete this Category?"
                        onConfirm={(e) => {
                          e.stopPropagation();
                          deleteCategory(category.id, {
                            onSuccess: (data) => {
                              message.warning("Category Deleted");
                              client.invalidateQueries("allCategories");
                              client.invalidateQueries("allProducts");
                            },
                            onError: (error) => {
                              message.warning(error?.response?.data?.message);
                            },
                          });
                        }}
                        onCancel={(e) => {
                          e.stopPropagation();
                          message.warning("ምንም ስራ አልተሰራም");
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <IoIosRemoveCircleOutline
                          className="text-red-600 text-xs ml-1 hover:text-base"
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Popconfirm>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="space-x-3">
          <Input.Search
            onChange={(e) => search(e.target.value)}
            placeholder="looking for which products ?"
            className="w-[45%]"
          />
          <Select
            onSearch={handleRestaurantSearch}
            showSearch
            filterOption={false}
            optionFilterProp="children"
            onChange={handleStoreChange}
            loading={load_restaurants}
            placeholder="Select Restaurant"
            className="w-60"
          >
            {searchedRestaurant?.map((restaurant) => {
              return (
                <Option key={restaurant.id} value={restaurant.id}>
                  <div className="flex flex-col">
                    {restaurant?.name}
                    <Rate
                      count={5}
                      value={restaurant.rating}
                      disabled
                      className="text-xs"
                    />
                  </div>
                </Option>
              );
            })}
          </Select>

          {/* <select
            id="restaurants"
            className="bg-gray-50 border border-blue-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-1"
            onChange={handleStoreChange}
          >
            <option selected disabled>
              Choose a Restaurant
            </option>
            {allRestaurants?.map((restaurant) => {
              return (
                <option key={restaurant.id} value={restaurant.id}>
                  {restaurant?.name}
                </option>
              );
            })}
          </select> */}

          <Popover
            content={buttons}
            title="Register New"
            placement="leftTop"
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <Button className="space-x-1 hover:cursor-pointer">
              <IoIosAddCircleOutline className="text-sm" />
              <span className="text-base">New</span>
            </Button>
          </Popover>
        </div>
        <div>
          {load_product ? (
            <div className="w-full ml-[90%] mt-[50%]">
              <Spin size="small" />
            </div>
          ) : data?.data?.length == 0 ? (
            <div className="w-full ml-[90%] mt-[50%]">
              <NoDataResult name="Product" />
            </div>
          ) : (
            <div>
              {(restaurant?.name || category?.name) && (
                <Breadcrumb className="mt-1 ml-3 px-4 py-1 text-sm w-fit">
                  <Breadcrumb.Item>{restaurant?.name}</Breadcrumb.Item>
                  <Breadcrumb.Item>{category?.name}</Breadcrumb.Item>
                </Breadcrumb>
              )}
              <div className="mt-2 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-3">
                {data?.data?.map((product, index) => {
                  return (
                    <div key={index}>
                      <Card
                        hoverable
                        key={index}
                        className="shadow-inner hover:border-[#c82127]"
                      >
                        <Image.PreviewGroup>
                          <Carousel autoplay infinite={false}>
                            {product?.image_paths.length ? (
                              product?.image_paths?.map(
                                (imagepath, imgindex) => {
                                  return (
                                    <Image key={imgindex} src={imagepath} />
                                  );
                                }
                              )
                            ) : (
                              <Image src={NoImage} />
                            )}
                          </Carousel>
                        </Image.PreviewGroup>
                        <Meta
                          key={index}
                          title={product?.name}
                          description={
                            <div className="flex flex-col">
                              <div>
                                {product.description?.length > 20
                                  ? product.description.slice(0, 20) + " ..."
                                  : product.description}
                              </div>
                              <div className="text-green-600">
                                {parseInt(product.discount) +
                                  parseInt(product.price) !=    
                                parseInt(product.price) ? (
                                  <strike className="text-red-600">
                                    {parseInt(product.discount) +
                                      parseInt(product.price)}{" "}
                                    ብር
                                  </strike>
                                ) : (
                                  ``
                                )}
                                {" " + product.price + " ብር"}
                                <br />
                                {product.image_paths.length === 1 ||
                                product.image_paths.length === 0
                                  ? product.image_paths.length + " image"
                                  : product.image_paths.length + " images"}
                                <br />
                              </div>
                              <div>
                                {product?.left_in_stock + " ስቶክ ላይ የቀረ"}
                              </div>
                              <div className="space-x-5">
                                <Switch
                                  size="small"
                                  checked={product?.is_active}
                                  onChange={() =>
                                    handleActivateDeactivateProductVisiblity(
                                      product
                                    )
                                  }
                                />
                                <EditOutlined
                                  className="text-green-600 text-base mr-3 hover:text-lg"
                                  onClick={() => {
                                    productForOperations(product);
                                    openEditProductDrawer();
                                  }}
                                />
                                <Popconfirm
                                  title="Delete Product"
                                  description="Are you sure to delete this Product?"
                                  onConfirm={(e) => {
                                    e.stopPropagation();
                                    deleteProduct(product.id, {
                                      onSuccess: (data) => {
                                        message.warning("Product Deleted");
                                        client.invalidateQueries("allProducts");
                                      },
                                      onError: (error) => {
                                        message.warning(
                                          error?.response?.data?.message
                                        );
                                      },
                                    });
                                  }}
                                  onCancel={(e) => {
                                    e.stopPropagation();
                                    message.warning("ምንም ስራ አልተሰራም");
                                  }}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <DeleteOutlined
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    className="text-red-600 text-base hover:text-lg"
                                  />
                                </Popconfirm>
                              </div>
                            </div>
                          }
                        />
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {/* </Row> */}
        <div className="mx-auto mt-4">
          {data?.meta?.last_page > 1 && (
            <Pagination
              current={page}
              total={data?.meta?.last_page * 10}
              showSizeChanger={false}
              position="bottomCenter"
              onChange={(page) => {
                handlePageChange(page);
              }}
              className="custom-pagination"
            />
          )}
        </div>
      </div>
      <Drawer
        open={addProductDrawer}
        closable={false}
        onClose={closeAddProductDrawer}
        width={600}
      >
        <AddProduct closeAddProductDrawer={closeAddProductDrawer} />
      </Drawer>
      <Drawer
        open={addCategoryDrawer}
        closable={false}
        onClose={closeAddCategoryDrawer}
      >
        <AddCategory closeAddCategoryDrawer={closeAddCategoryDrawer} />
      </Drawer>

      <Drawer
        onClose={closeEditProductDrawer}
        open={editProductDrawer}
        closable={false}
        width={600}
      >
        <EditProduct
          closeEditProductDrawer={closeEditProductDrawer}
          productForOperations={productForOperation}
        />
      </Drawer>
      <Drawer
        closable={false}
        open={editCategoryDrawer}
        onClose={closeEditCategoryDrawer}
      >
        <EditCategory
          categoryForOperations={categoryForOperation}
          closeEditCategoryDrawer={closeEditCategoryDrawer}
        />
      </Drawer>
    </div>
  );
};

export default Products;
