// @author: Yohannes Assebe
import { useQuery, useMutation } from "react-query";
import api from "../adapter/base";
const appSetting = async () => {
  const res = await api.get("mobile-app");
  const response = res?.data?.data;
  return response;
};
const updateAppSetting = (setting) => {
  return api.put("mobile-app", setting);
};

export const useAppSetting = () => {
  return useQuery("app-setting", appSetting);
};

export const useUpdateSetting = () => {
  return useMutation(updateAppSetting);
};

