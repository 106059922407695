/* eslint-disable */
// @author : Yohannes Assebe
import { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, message } from "antd";
import { useAppSetting, useUpdateSetting } from "../../hooks/useSiteSettings";
import { useQueryClient } from "react-query";
const SiteSettings = () => {
  const [form] = Form.useForm();
  const [initialData, setInitialData] = useState();
  const { data: app_settings } = useAppSetting();
  const { mutate: update_app_setting, isLoading: update_app_setting_load } =
    useUpdateSetting();
  const client = useQueryClient();
  const onFinish = (values) => {
    update_app_setting(values, {
      onSuccess: (data) => {
        message.success("App Settings Updated");
        client.invalidateQueries("app-setting");
      },
      onError: (error) => {
        message.warning(error?.response?.data?.message);
      },
    });
  };
  useEffect(() => {
    setInitialData(app_settings);
    form.setFieldsValue(initialData);
  }, [initialData, app_settings, form]);
  return (
    <>
      <div className="max-w-6xl mt-4 ml-11">
        <Form onFinish={onFinish} form={form} layout="vertical">
          <Row>
            <Col span={24}>
              <div className="ml-10">
                <Row>
                  <Col span={24}>
                    <h5 className="text-base font-bold">APP SETTINGS</h5>
                  </Col>
                  <Col span={24}>
                    <span>Set the settings related app version</span>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Row>
                <Col span={24}></Col>
                <Col span={24}>
                  <Form.Item
                    label="App Name"
                    name="app_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input App Name!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item label="Privacy Policy Link" name="privacy_policy_link">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Row>
                <Col span={24}></Col>
                <Col span={24}>
                  <Form.Item
                    label="Terms And Conditions Link"
                    name="terms_and_conditions_link"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item
                label="Latest Android Version"
                name="latest_android_version"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Row>
                <Col span={24}></Col>
                <Col span={24}>
                  <Form.Item
                    label="Least Supported Android Version"
                    name="least_supported_android_version"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item label="Play Store Link" name="play_store_link">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Row>
                <Col span={24}></Col>
                <Col span={24}>
                  <Form.Item
                    label="Latest IOS Version"
                    name="latest_ios_version"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item
                label="Least Supported IOS Version"
                name="least_supported_ios_version"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Row>
                <Col span={24}></Col>
                <Col span={24}>
                  <Form.Item label="App Store Link" name="app_store_link">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8} offset={1}></Col>
          </Row>

          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Form.Item>
                <Button htmlType="submit" loading={update_app_setting_load}>
                  Save
                </Button>
              </Form.Item>
            </Col>
            <Col span={8}></Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default SiteSettings;
