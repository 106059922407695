/*eslint-disable*/
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Upload, Input, Divider, message, Select } from "antd";
import { useAddCategory } from "../hooks/useCategories";
import { useAllRestaurantsWithoutPagination } from "../hooks/useRestaurant";
import { useQueryClient } from "react-query";
const formlayout = {
  labelCol: {
    span: 20,
  },
  wrapperCol: {
    span: 24,
  },
};
const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);

const { Option } = Select;
const AddCategory = ({ closeAddCategoryDrawer }) => {
  const [form] = Form.useForm();
  //query mutation to register Category
  const { mutate: addCategory, isLoading: load } = useAddCategory();

  // All Restaurants
  const { data: restaurants, isLoading: load_restaurants } =
    useAllRestaurantsWithoutPagination();

  const client = useQueryClient();
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onReset = () => {
    form.resetFields();
  };
  // register button click handler
  const onFinish = (values) => {
    Object?.keys(values)?.map((key) => {
      if (typeof values[key] == "undefined") {
        delete values[key];
        return;
      }
    });
    const fd = new FormData();
    if (values["upload"]?.length) {
      fd.append(
        "image",
        values["upload"][0].originFileObj,
        values["upload"][0].originFileObj.name
      );
      delete values["upload"];
    } else {
      delete values["upload"];
    }
    Object.keys(values).map((key) => {
      return fd.append(key, values[key]);
    });

    addCategory(fd, {
      onSuccess: (data) => {
        message.success(data?.data?.data?.name + " Registered");
        client.invalidateQueries("category");
        form.resetFields();
        closeAddCategoryDrawer();
      },
      onError: (error) => {
        message.warning(error?.response?.data?.message);
      },
    });
  };

  return (
    <div className="items-center text-center -mt-4">
      <Divider>
        <h4>Regsiter New Category</h4>
      </Divider>
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        {...formlayout}
        style={{
          maxWidth: 300,
        }}
      >
        <Form.Item
          label="Category Name"
          name="name"
          rules={[
            {
              required: true,
              message: "category name is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ስም በአማርኛ"
          name="name_am"
          rules={[
            {
              required: true,
              message: "category name in amharic is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Restaurant"
          name="store_id"
          rules={[
            {
              required: true,
              message: "Please Select Restaurant",
            },
          ]}
        >
          <Select
            placeholder="Select Restaurant"
            loading={load_restaurants}
            className="placeholder-slate-50"
          >
            {restaurants?.map((restaurant) => {
              return (
                <Option value={restaurant.id} key={restaurant.id}>
                  {restaurant.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          className="text-center"
          name="upload"
          label="የምድቡ ምስል"
          valuePropName="fileList"
          accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
          getValueFromEvent={normFile}
          multiple={false}
        >
          <Upload
            name="upload"
            listType="picture-card"
            multiple={false}
            maxCount={1}
            beforeUpload={(file) => {
              return false;
            }}
          >
            {uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item>
          <div className="flex flex-row gap-6 pl-6 mt-3">
            <Button
              onClick={onReset}
              className="rounded-lg w-32 h-8 border-1 border-[#C82127] text-[#C82127]"
            >
              reset
            </Button>
            <Button
              className="rounded-lg w-32 h-8 border-1 border-slate-600"
              htmlType="submit"
              loading={load}
            >
              Register
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddCategory;
