// @author : Yohannes Assebe
import "antd/dist/reset.css";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider, QueryClient } from "react-query";
import Login from "./common/login";
import Home from "./common/layout";
import SiteSettings from "./Admin/views/siteSettings";
import CustomerManagement from "./Admin/views/customerManagement";
import DashBoard from "./Admin/views/dashboard";
import FullFillment from "./Admin/views/fullfillment";
import Products from "./Admin/views/products";
import PushNotification from "./Admin/views/pushNotification";
import RBAC from "./Admin/views/rbac";
import Report from "./Admin/views/reports";
import Restaurant from "./Admin/views/restaurant";
import Vehicles from "./Admin/views/vehicles";
import SpecialOffers from "./Admin/views/specialOffers";
import NotFoundPage from "./Admin/components/notFound404";
import LandingPage from "./Admin/components/landingPage";
import RestaurantType from "./Admin/views/restaurantType";
import CourierType from "./Admin/views/courierTypes";
const client = new QueryClient();
function App() {
  return (
    <>
      <QueryClientProvider client={client}>
        <Router>
          <Routes>
            {["/", "/login"].map((path, index) => (
              <Route path={path} element={<Login />} key={index} />
            ))}
            <Route path="/admin" element={<Home />}>
              <Route index element={<LandingPage />} />
              <Route path="dashboard" element={<DashBoard />} />
              <Route path="site_settings" element={<SiteSettings />} />
              <Route
                path="customer_management"
                element={<CustomerManagement />}
              />
              <Route path="fullfillment" element={<FullFillment />} />
              <Route path="products" element={<Products />} />
              <Route path="push_notification" element={<PushNotification />} />
              <Route path="rbac" element={<RBAC />} />
              <Route path="report" element={<Report />} />
              <Route path="restaurant" element={<Restaurant />} />
              <Route path="vehicles" element={<Vehicles />} />
              <Route path="special_offers" element={<SpecialOffers />} />
              <Route path="restaurant_type" element={<RestaurantType />} />
              <Route path="courier_type" element={<CourierType />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      </QueryClientProvider>
    </>
  );
}

export default App;
