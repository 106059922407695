/* eslint-disable */
import { useState } from "react";
import { Table, Spin, Button, message, Modal } from "antd";
import {
  useRestaurantTypes,
  useDeleteRestaurantType,
} from "../../hooks/useRestaurantType";
import AddRestaurantType from "../../modals/popover/addRestaurantType";
import { IoRestaurantOutline } from "react-icons/io5";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useQueryClient } from "react-query";
import RestaurantInTheirType from "../components/restaurantIntheirType";
import EditRestaurantType from "../../modals/editRestaurantType";
import { PlusOutlined } from "@ant-design/icons";
import "../../assets/styles.css";
const RestaurantType = () => {
  const { data: restaurantTypes, isLoading: load_restaurant } =
    useRestaurantTypes();

  const client = useQueryClient();

  // variables used to control the visiblity of EditModal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  // variable and function used to pass the restaurantType for EditRestaurantType Component
  const [restaurantType, setRestaurantType] = useState();
  const restaurantTypeForOperation = (restaurantTyp) => {
    setRestaurantType(restaurantTyp);
    openModal();
  };

  // pagination will be rendered if page is greater than 1
  // const isPagination =
  //   restaurantTypes?.meta?.last_page === 1
  //     ? null
  //     : {
  //         current: page,
  //         total: restaurantTypes?.meta?.last_page * 10,
  //         showSizeChanger: false,
  //         position: ["bottomCenter"],
  //       };

  // table columns
  const columns = [
    { title: "Restaurant Types", dataIndex: "name", key: "name" },
    {
      render: (record) => {
        return (
          <div className="flex flex-row gap-3">
            <AiOutlineEdit
              className="text-sky-500 text-base hover:cursor-pointer hover:text-sky-800"
              onClick={() => restaurantTypeForOperation(record)}
            />
            <RiDeleteBin5Fill
              className="text-red-500 text-base hover:cursor-pointer hover:text-red-700"
              onClick={() => deleteRestaurantType(record.id)}
            />
          </div>
        );
      },
    },
  ];

  // function used to delete restaurant Type
  const { mutate: deleteRestaurantTypee, isLoading: load_delete } =
    useDeleteRestaurantType();
  const deleteRestaurantType = (id) => {
    deleteRestaurantTypee(id, {
      onSuccess: (response) => {
        message.warning(response?.message);
        client.invalidateQueries("restaurantTypes");
      },
      onError: (err) => {
        const errorMessage = err?.response?.data?.message;
        message.warning(errorMessage);
      },
    });
  };

  // variables used to control the visiblity of popOver
  const [isPopOverVisible, setIsPopOverVisible] = useState(false);
  const closePopOver = () => setIsPopOverVisible(false);
  const togglePopOver = () => setIsPopOverVisible((visible) => !visible);

  // const title = (
  //   <div className="w-full">
  //     <Button
  //       className="rounded-lg w-fit h-8 border-1 border-slate-600"
  //       onClick={togglePopOver}
  //       icon={<IoRestaurantOutline />}
  //     >
  //       Add
  //     </Button>
  //   </div>
  // );

  return (
    <div className="max-w-2xl mx-auto mt-5">
      <Table
        columns={columns}
        dataSource={restaurantTypes?.data?.map((resType) => ({
          ...resType,
          key: resType.id,
        }))}
        loading={{
          indicator: <Spin size="small" />,
          spinning: load_restaurant,
        }}
        pagination={false}
        // title={() => title}
        expandable={{
          expandedRowRender: (record) => {
            return <RestaurantInTheirType restaurantTypeId={record?.id} />;
          },
        }}
        bordered
      />
      <Modal
        open={isModalVisible}
        closable={false}
        onCancel={closeModal}
        footer={null}
      >
        <EditRestaurantType
          closeEditPopOver={closeModal}
          restaurantType={restaurantType}
        />
      </Modal>
      <Modal
        open={isPopOverVisible}
        onCancel={closePopOver}
        closable={false}
        footer={null}
      >
        <AddRestaurantType closePopOver={closePopOver} />
      </Modal>
      <div className="add_courier_type_button_container">
        <Button
          className="add_courier_type_button"
          type="primary"
          icon={<PlusOutlined style={{ fontSize: 22, marginTop: 4 }} />}
          onClick={togglePopOver}
        />
      </div>
    </div>
  );
};
export default RestaurantType;
