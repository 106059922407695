/*eslint-disable*/
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Upload,
  InputNumber,
  message,
  Divider,
} from "antd";
import { useQueryClient } from "react-query";
import { PlusOutlined } from "@ant-design/icons";
import { useAllRestaurantsWithoutPagination } from "../hooks/useRestaurant";
import { useRestaurantCategories } from "../hooks/useCategories";
import { useEditProduct } from "../hooks/useProducts";
import { IoSaveOutline } from "react-icons/io5";
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);

const { Option } = Select;
const EditProduct = ({ productForOperations, closeEditProductDrawer }) => {
  const [initialData, setInitialData] = useState();
  const [restaurantId, setRestaurantId] = useState();
  const client = useQueryClient();
  const [form] = Form.useForm();

  //mutation and query data fetched from the api
  const { data: restaurants, isLoading: load_res } =
    useAllRestaurantsWithoutPagination();
  const { data: categories, isLoading: load_categories } =
    useRestaurantCategories(restaurantId);
  const { mutate: updateProduct, isLoading: load_update_product } =
    useEditProduct();

  useEffect(() => {
    setRestaurantId(productForOperations?.store_id);
    setInitialData(productForOperations);
    form.setFieldsValue(initialData);
  }, [initialData, form, productForOperations]);

  const onFinish = (values) => {
    Object?.keys(values)?.map((key) => {
      if (Object.is(values[key], undefined) || Object.is(values[key], null)) {
        delete values[key];
        return;
      }
    });
    const fd = new FormData();
    Object?.keys(values)?.map((key) => {
      if (key === "upload" && values["upload"]?.length) {
        values["upload"]?.map((image) => {
          fd.append("images[]", image.originFileObj, image.originFileObj.name);
        });
        fd.append("remove_images", 1);
        delete values["upload"];
        return;
      }
      if (key === "store_quantities") {
        for (let i = 0; i < values["store_quantities"]?.length; i++) {
          Object.keys(values["store_quantities"][i]).forEach((key) => {
            fd.append(
              `store_quantities[${i}][${key}]`,
              values["store_quantities"][i][key]
            );
          });
        }
        delete values["store_quantities"];
        return;
      }

      fd.append(key, values[key]);
      fd.append("_method", "put");
      updateProduct(
        { id: productForOperations?.id, data: fd },
        {
          onSuccess: (data) => {
            message.success(data?.data?.data?.name + " Updated");
            client.invalidateQueries("allProducts");
            closeEditProductDrawer();
          },
          onError: (error) => {
            message.warning(error?.response?.data?.message);
          },
        }
      );
    });
  };

  return (
    <div className="-mt-7 max-h-screen">
      <Divider>
        <h4>Update Product</h4>
      </Divider>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Product Name"
              rules={[
                {
                  required: true,
                  message: "Please Enter Product Name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item
              name="price"
              label="የእቃው ዋጋ"
              rules={[
                {
                  required: true,
                  message: "Price field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="name_am"
              label="የእቃው ስም በአማርኛ"
              rules={[
                {
                  required: true,
                  message: "እባኮን የእቃውን ስም ያስገቡ",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item
              name="discount"
              label="ዋጋው ላይ የተደረገ ቅናሽ"
              rules={[
                {
                  required: true,
                  message: "Price discount",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="description"
              label="Description In English"
              rules={[
                {
                  required: false,
                  message: "Please Enter Appropriate Description",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item
              name="store_id"
              label="Restaurant"
              rules={[
                { required: true, message: "Restaurant Field is required" },
              ]}
            >
              <Select loading={load_res} className="w-full" disabled={true}>
                {restaurants?.map((restaurant) => {
                  return (
                    <Option value={restaurant?.id} key={restaurant?.id}>
                      {restaurant?.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="description_am"
              label="ገለጻ ስለ እቃው(አማርኛ)"
              rules={[
                {
                  required: false,
                  message: "እባኮን መግለጫውን አስተካክለው ያስገቡ",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item
              name="category_id"
              label="Category"
              rules={[
                { required: true, message: "Category Field is required" },
              ]}
            >
              <Select loading={load_categories} className="w-full">
                {categories?.map((category) => {
                  return (
                    <Option value={category?.id} key={category?.id}>
                      {category?.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="upload"
              label="Images For Product"
              valuePropName="fileList"
              accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
              getValueFromEvent={normFile}
            >
              <Upload
                name="images"
                listType="picture-card"
                beforeUpload={(file) => {
                  return false;
                }}
              >
                {uploadButton}
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="mt-[35%]">
              <Button
                htmlType="submit"
                loading={load_update_product}
                className="rounded-lg w-32 h-8 border-1 border-slate-600 "
              >
                Update
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default EditProduct;
