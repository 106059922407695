/* eslint-disable */
import { useState } from "react";
import api from "../adapter/base";
import { message } from "antd";
import { useQuery, useMutation, useQueryClient } from "react-query";

const fetchCourierTypes = async () => {
  const response = await api.get(`courier-types`);
  return response.data;
};

export const useCourierTypes = () => {
  return useQuery("courier-types", fetchCourierTypes);
};

const registerCourierType = async (courierType) => {
  const response = await api.post("courier-types", courierType);
  return response;
};

export const useRegisterCourierType = (onSuccessCallback) => {
  const client = useQueryClient();
  const mutation = useMutation(registerCourierType, {
    onSuccess: (data) => {
      message.success("courier type registered");
      client.invalidateQueries("courier-types");
      onSuccessCallback();
    },
    onError: (error) => {
      var err;
      if (error?.response?.status === 500) {
        err = "Server Error";
      } else {
        err = error?.response?.data?.message ?? " something goes wrong! ";
      }
      message.warning(err);
    },
  });
  return { mutation };
};

const updateCourierType = async (courierType) => {
  const response = await api.put(
    `courier-types/${courierType.id}`,
    courierType
  );
  return response;
};

export const useUpdateCourierType = (onSuccessCallback) => {
  const client = useQueryClient();
  const mutation = useMutation(updateCourierType, {
    onSuccess: (data) => {
      message.success("courier type updated");
      client.invalidateQueries("courier-types");
      onSuccessCallback();
    },
    onError: (error) => {
      var err;
      if (error?.response?.status === 500) {
        err = "Server Error";
      } else {
        err = error?.response?.data?.message ?? " something goes wrong! ";
      }
      message.warning(err);
    },
  });
  return { mutation };
};

const deleteCourierType = async (id) => {
  const res = await api.delete(`courier-types/${id}`);
  return res;
};

export const useDeleteCourierType = () => {
  const client = useQueryClient();
  const mutation = useMutation(deleteCourierType, {
    onSuccess: (data) => {
      message.warning("Courier Type Deleted! ");
      client.invalidateQueries("courier-types");
    },
    onError: (error) => {
      var err;
      if (error?.response?.status === 500) {
        err = "Server Error";
      } else {
        err = error?.response?.data?.message ?? " something goes wrong! ";
      }
      message.warning(err);
    },
  });
  return { mutation };
};
