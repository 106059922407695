/* eslint-disable */
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Table, Button, message, Tag, Spin } from "antd";
import api from "../../adapter/base";
import OrderDetails from "./orderDetail";
import { useQueryClient } from "react-query";
import { HiOutlineLocationMarker } from "react-icons/hi";
import {
  useDeliveryStatus,
  useRetryPickUpDelivery,
  useViewTripStatus,
} from "../../hooks/useOrder";
import echo from "../../wsconfig/websocket";
import MapBox from "../map/mapbox";
const fetchPendingDeliveryOrders = async (page = 1) => {
  const response = await api.get(
    `orders?status=pending-delivery&user&page=${page}`
  );
  const data = await response.data;
  return data;
};
function PendingDelivery() {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useQuery(["pending-delivery-orders", page], () =>
    fetchPendingDeliveryOrders(page)
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleLocation = (location) => {
    setSelectedLocation(location);
    setModalVisible(true);
  };
  const handleCloseModal = () => {
    setModalVisible(false);
  };
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [key, setKey] = useState(0);
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  const onRow = (record) => {
    return {
      onClick: () => {
        if (record.is_courier) {
          message.warning("No product Registered in the courier Orders");
          return;
        }
        setSelectedRowData(record);
        setKey(key + 1);
      },
    };
  };
  const { mutate: check_delivery_status, isLoading: load_delivery_status } =
    useDeliveryStatus();
  const { mutate: retry_pickup_delivery, isLoading: load_retry_pickup } =
    useRetryPickUpDelivery();
  const { mutate: viewTrip, isLoading: load_view_trip } = useViewTripStatus();
  const client = useQueryClient();
  // check status of delivery
  const checkStatus = (order_id) => {
    check_delivery_status(order_id, {
      onSuccess: (data) => {
        const res_message = data?.data?.status.includes("_")
          ? data?.data?.status.split("_").join(" ").toLowerCase()
          : data?.data?.status;
        message.success(res_message);
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };

  // retry pickup
  const retryPickup = (order_id) => {
    retry_pickup_delivery(order_id, {
      onSuccess: (data) => {
        message.success(data?.data?.message);
      },
      onError: (error) => {
        message.warning(error?.response?.data?.message);
      },
    });
  };
  // handle view trips
  const handleViewTrip = (order_id) => {
    viewTrip(order_id, {
      onSuccess: (data) => {
        const res_message = data?.data?.message?.includes(":")
          ? data?.data?.message.split(":")[1]
          : data?.data?.message;
        if (data?.data?.message?.includes(":")) message.warning(res_message);
        else window.open(data?.data?.url, "_blank");
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };
  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer Name",
      dataIndex: ["user", "name"],
      key: "customerName",
    },
    {
      title: "Phone Number",
      dataIndex: ["user", "phone_number"],
      key: "phoneNumber",
    },
    {
      title: "Total Cost",
      dataIndex: "total_cost",
      key: "ordercost",
      render: (total_cost) => {
        return total_cost + " ብር";
      },
    },
    {
      title: "Approved By",
      dataIndex: ["approver", "name"],
      key: "approver",
    },
    {
      title: "Approved At",
      dataIndex: "approved_at",
      key: "approved_at",
    },
    {
      dataIndex: "is_courier",
      render: (is_courier, index) => {
        return (
          <Tag key={index} color={is_courier ? "gray" : "green"}>
            {is_courier ? "Courier" : "Normal"}
          </Tag>
        );
      },
    },
    {
      title: "Distance(KM)",
      dataIndex: ["shipping_detail", "distance_in_km"],
      render: (distance) => {
        return distance ?? "...";
      },
    },
    {
      title: "Location",
      key: "",
      render: (record) => (
        <HiOutlineLocationMarker
          className="text-[#C82127] text-lg hover:cursor-pointer"
          title="Click to view location on Map"
          aria-label="Location marker"
          onClick={(e) => {
            e.stopPropagation();
            handleLocation({
              lat: parseFloat(record.shipping_detail.latitude),
              lng: parseFloat(record.shipping_detail.longitude),
            });
          }}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (orderId) => {
        return (
          <div key={orderId}>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                checkStatus(orderId);
              }}
            >
              Check Status
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                retryPickup(orderId);
              }}
            >
              Retry Pickup
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleViewTrip(orderId);
              }}
            >
              View Trip
            </Button>
          </div>
        );
      },
    },
  ];

  // listen for approved orders socket
  useEffect(() => {
    echo.private(`orders.status-change`).listen("OrderApproved", (data) => {
      console.log("Socket Event Listened Correctly,Johnny :)", data);
      client.invalidateQueries(["pending-delivery-orders", 1]);
    });

    return () => {
      echo.leave(`orders.status-change`);
    };
  }, []);

  return (
    <>
      <Table
        columns={columns}
        dataSource={data?.data?.map((dt) => ({ ...dt, key: dt.id }))}
        pagination={{
          current: page,
          position: ["bottomCenter"],
          total: data?.meta?.last_page * 10,
          showSizeChanger: false,
          className:"custom-pagination"
        }}
        loading={{
          indicator: <Spin size="small" />,
          spinning:
            isLoading ||
            load_delivery_status ||
            load_retry_pickup ||
            load_view_trip,
        }}
        onChange={handleTableChange}
        onRow={onRow}
      />
      {selectedRowData && (
        <OrderDetails
          order={selectedRowData}
          key={key}
          onClose={() => setSelectedRowData(null)}
        />
      )}
      {selectedLocation && modalVisible && (
        <MapBox
          visible={modalVisible}
          latitude={selectedLocation.lat}
          longitude={selectedLocation.lng}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
}

export default PendingDelivery;
