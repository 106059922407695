/* eslint-disable*/
// @author: Yohannes Assebe
import { Button, Form, Input, Upload, Select, Divider, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useQueryClient } from "react-query";
import {
  useAddStoreManager,
  useAllRestaurantsWithoutPagination,
} from "../hooks/useRestaurant";
const AddRestaurantManager = ({ closeAddRestaurantManagerDrawer }) => {
  const [form] = Form.useForm();
  //react-query client
  const client = useQueryClient();
  const { data: restaurants, isLoading: res_load } =
    useAllRestaurantsWithoutPagination();
  const { Option } = Select;

  const onSuccess = (data) => {
    message.success("Manager Registered Successfully");
    client.invalidateQueries("allRestaurant");
    closeAddRestaurantManagerDrawer();
  };
  const onError = (error) => {
    message.warning(error?.response?.data?.message);
  };

  const { mutate: registerRestaurantManager, isLoading: load } =
    useAddStoreManager(onSuccess, onError);
  //function used to handle reset fileds
  const onReset = () => {
    form.resetFields();
  };
  // function to register restaurant managers
  const onFinish = (values) => {
    if (values.password !== values.password_confirmation) {
      message.warning("password confirmation is not the same as password!");
      return;
    }
    const fd = new FormData();
    Object?.keys(values)?.map((key) => {
      if (typeof values[key] == "undefined") {
        delete values[key];
        return;
      }
    });
    Object.keys(values).map((key) => {
      if (key === "upload" && values["upload"]) {
        fd.append(
          "profile_image",
          values["upload"][0].originFileObj,
          values["upload"][0].originFileObj.name
        );
        delete values["upload"];
      }
      fd.append(key, values[key]);
    });
    registerRestaurantManager(fd);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <div className="-mt-5">
      <Divider>Register Restaurant Manager</Divider>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[
            {
              required: true,
              message: "Manager First Name is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[
            {
              required: true,
              message: "Manager Last Name is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="phone_number"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Manager Phone Number is required",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="2519.....(12)"
            maxLength={12}
            showCount
          />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email Account"
          rules={[
            {
              type: "email",
              required: true,
              message: "Manager Email is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          name="store_id"
          label="Restaurant"
          rules={[
            {
              required: true,
              message: "Manager WorkPlace is required",
            },
          ]}
        >
          <Select loading={res_load}>
            {restaurants?.map((rest) => {
              return (
                <Option value={rest?.id} key={rest?.id}>
                  {rest?.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              type: "password",
              message: "Password Field is required",
            },
          ]}
        >
          <Input size="large" type="password" />
        </Form.Item>

        <Form.Item
          name="password_confirmation"
          label="Password Confirmation"
          rules={[
            {
              required: true,
              type: "password",
              message: "Password Field is required",
            },
          ]}
        >
          <Input size="large" type="password" />
        </Form.Item>
        <Form.Item
          name="upload"
          label="Image Of Manager"
          valuePropName="fileList"
          accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: "እባኮን ቢያንስ አንድ ፎቶ ይጨምሩ/this field is required",
            },
          ]}
        >
          <Upload
            name="upload"
            listType="picture-card"
            maxCount={1}
            beforeUpload={(file) => {
              return false;
            }}
          >
            {uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item>
          <div className="flex flex-row mt-6 pl-6 gap-6">
            <Button
              onClick={onReset}
              className="rounded-lg w-32 h-8 border-1 border-[#C82127] text-[#C82127]"
            >
              reset
            </Button>
            <Button
              htmlType="submit"
              loading={load}
              className="rounded-lg w-32 h-8 border-1 border-slate-600"
            >
              + Manager
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddRestaurantManager;
