// @author : Yohannes Assebe
import { List, Avatar } from "antd";
import { isEqual } from "lodash";
const RestaurantProducts = ({ restaurantForOperation }) => {
  return (
    <>
      <List
        size="large"
        header={
          <div>
            {
              <div className="font-bold text-center text-2xl font-mono">
                {restaurantForOperation?.name + "'s Products"}
              </div>
            }
          </div>
        }
        footer={<em>@ZowiTech</em>}
        bordered
        dataSource={restaurantForOperation?.products}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <List.Item.Meta
              title={item?.name}
              avatar={<Avatar src={item?.image_paths[0]} />}
              description={
                <div className="flex flex-col font-mono">
                  <div>{item?.description}</div>
                  <div className="flex flex-row text-center items-center">
                    {!isEqual(
                      (
                        parseFloat(item?.price) + parseFloat(item?.discount)
                      ).toFixed(2),
                      parseFloat(item?.price).toFixed(2)
                    ) && (
                      <strike className="text-red-600 pr-2">
                        {(
                          parseFloat(item?.price) + parseFloat(item?.discount)
                        ).toFixed(2) + " ብር"}
                      </strike>
                    )}
                    <div className="text-green-500">{item?.price + " ብር"}</div>
                  </div>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
};
export default RestaurantProducts;
