/*eslint-disable*/
import { useEffect, useState } from "react";
import {
  Button,
  Avatar,
  Row,
  Col,
  Card,
  Badge,
  Table,
  message,
  Modal,
  Drawer,
} from "antd";
import {
  useGetRolesWithUsers,
  getRole,
  usePermissonGroups,
  useGetRoles,
  useUpdateRole,
  useDeleteRole,
} from "../../hooks/useSystemUser";
import { useQueryClient } from "react-query";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import AddRole from "../../modals/addRole";
import AddRoleUser from "../../modals/addRoleUser";
import RoleUsers from "../../drawer/roleUser";
import { getRoleWithUser } from "../../hooks/useSystemUser";
const RBAC = () => {
  //users with their role fetched
  const { data: usersWithRole, isLoading: loadUserWithRole } =
    useGetRolesWithUsers();
  //fetch all roles
  const { data: roles } = useGetRoles();

  //fetch all permission groups
  const { data: permission_groups, isLoading: loadPermissionGroup } =
    usePermissonGroups();

  //update permission groups
  const { mutate: updatePermissions, isLoading: loadUpdatePermission } =
    useUpdateRole();

  //delete role
  const { mutate: deleteRole, isLoading: loadDeleteRole } = useDeleteRole();

  // variables to assign different roles
  const [admins, setAdmins] = useState();
  const [managers, setManagers] = useState();
  const [viewers, setViewers] = useState();

  // selected keys for permission update
  const [selectedkeys, setSelectedkeys] = useState([]);
  const [roleid, setRoleId] = useState();
  const [load, setLoad] = useState(false);

  // add role modal controllers
  const [addRoleModal, setAddRoleModal] = useState(false);
  const openAddRoleModal = () => {
    setAddRoleModal(true);
  };
  const closeAddRoleModal = () => {
    setAddRoleModal(false);
  };
  // add user modal controllers
  const [addRoleUserModal, setAddRoleUserModal] = useState(false);
  const openAddRoleUserModal = () => {
    setAddRoleUserModal(true);
  };
  const closeAddRoleUserModal = () => {
    setAddRoleUserModal(false);
  };

  // list roleUser drawer controllers
  const [roleUserDrawer, setRoleUserDrawer] = useState(false);
  const [roleForOp, setRoleForOp] = useState();
  const openRoleUserDrawer = () => {
    setRoleUserDrawer(true);
  };
  const closeRoleUserDrawer = () => {
    setRoleUserDrawer(false);
  };
  const roleForOperation = (role) => {
    setRoleForOp(role);
  };

  //used to invalidate react-queries data
  const client = useQueryClient();
  //When Role button list clicked once it will show their permissions
  const checkClikes = async (event, role) => {
    switch (event.detail) {
      case 1: {
        setLoad(true);
        const rolee = await getRole(role.id);
        setSelectedkeys([]);
        rolee?.permission_groups?.map((permission) => {
          return setSelectedkeys((prev) => {
            return [...prev, permission?.id];
          });
        });
        setLoad(false);
        break;
      }
      case 2: {
        setLoad(true);
        const roleUser = await getRoleWithUser(role.id);
        roleForOperation(roleUser);
        openRoleUserDrawer();
        break;
      }
      default: {
        console.log("case3");
        break;
      }
    }
  };

  const UpdateRolePermission = (id) => {
    if (!id) {
      message.warning("Please Select Role First");
      return;
    }
    setLoad(true);
    updatePermissions(
      { id: id, data: { permission_groups: selectedkeys } },
      {
        onSuccess: (data) => {
          message.success(
            data?.data?.data?.title + " Permission Updated Successfully"
          );
        },
        onError: (err) => {
          message.warning(err?.response?.data?.message);
        },
      }
    );
    setLoad(false);
  };

  const DeleteRole = (id) => {
    if (!id) {
      message.warning("Please Select Role First");
      return;
    }
    deleteRole(id, {
      onSuccess: () => {
        message.warning("deleted Successfully");
        client.invalidateQueries("roles");
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };

  //table coumns
  const columns = [
    {
      key: "1",
      title: "Permission Id",
      dataIndex: "id",
      render: (id, record, index) => {
        return ++index;
      },
    },
    {
      key: "2",
      title: "Permission",
      dataIndex: "title",
    },
  ];

  //this useEffect used to attach
  useEffect(() => {
    setAdmins(usersWithRole?.filter((user) => user?.title === "Admin"));
    setManagers(usersWithRole?.filter((user) => user?.title === "Manager"));
    setViewers(usersWithRole?.filter((user) => user?.title === "Viewer"));
  }, [usersWithRole]);

  return (
    <div>
      <Button className="mt-[3%] ml-[5%]" onClick={() => openAddRoleModal()}>
        + አዲስ Role
      </Button>
      <Button
        className="mt-[3%] ml-[1%]"
        onClick={() => {
          openAddRoleUserModal();
        }}
      >
        + አዲስ User
      </Button>
      <h6 className="mt-[20px] ml-[8%] text-[#252733] text-lg font-bold">
        ሃላፊነቶች
      </h6>
      <Row gutter={[16, 16]} style={{ marginLeft: "15%" }}>
        <Col xs={16} md={12} xl={8}>
          <Card
            hoverable
            className="w-[320px] h-[100px] border-[#DFE0EB]"
            loading={loadUserWithRole}
          >
            <div className="flex flex-row-reverse justify-between">
              <div className="flex flex-col -ml-5">
                <h6 className="text-base">
                  {admins && admins[0]?.users_count} accounts
                </h6>
                <h6 className="font-bold text-lg">Admin</h6>
              </div>
              <div className="ml-8">
                <Avatar.Group
                  maxCount={3}
                  maxStyle={{
                    color: "#FFF",
                    backgroundColor: "#FAAD07",
                  }}
                >
                  {admins &&
                    admins[0]?.users?.map((admin, index) => {
                      return (
                        <Avatar
                          key={index}
                          src={admin?.profile_thumbnail_path}
                        />
                      );
                    })}
                </Avatar.Group>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={16} md={12} xl={8}>
          <Card
            hoverable
            className="w-[320px] h-[100px] border-[#DFE0EB]"
            loading={loadUserWithRole}
          >
            <div className="flex flex-row-reverse justify-between">
              <div className="flex flex-col -ml-5">
                <h6 className="text-base">
                  {managers && managers[0]?.users_count} accounts
                </h6>
                <h6 className="font-bold text-xl">Manager</h6>
              </div>
              <div className="ml-8">
                <Avatar.Group
                  maxCount={3}
                  maxStyle={{
                    color: "#FFF",
                    backgroundColor: "#FAAD07",
                  }}
                >
                  {managers &&
                    managers[0]?.users?.map((manager, index) => {
                      return (
                        <Avatar
                          key={index}
                          src={manager?.profile_thumbnail_path}
                        />
                      );
                    })}
                </Avatar.Group>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={16} md={12} xl={8}>
          <Card
            hoverable
            className="w-[320px] h-[100px] border-[#DFE0EB]"
            loading={loadUserWithRole}
          >
            <div className="flex flex-row-reverse justify-between">
              <div className="flex flex-col -ml-5">
                <h6 className="text-base">
                  {viewers && viewers[0]?.users_count} accounts
                </h6>
                <h6 className="font-bold text-xl">Viewers</h6>
              </div>
              <div className="ml-8">
                <Avatar.Group
                  maxCount={3}
                  maxStyle={{
                    color: "#FFF",
                    backgroundColor: "#FAAD07",
                  }}
                >
                  {viewers &&
                    viewers[0]?.users?.map((viewer, index) => {
                      return (
                        <Avatar
                          key={index}
                          src={viewer?.profile_thumbnail_path}
                        />
                      );
                    })}
                </Avatar.Group>
              </div>
            </div>
          </Card>
        </Col>
      </Row>

      <div className="ml-[5%]">
        {roles?.map((role) => {
          return (
            <Button
              key={role?.id}
              onClick={(e) => {
                checkClikes(e, role);
                setRoleId(role.id);
              }}
              className="mt-[5%] ml-[1%] bg-white"
            >
              <span className="border-xl">
                <Badge
                  // className="bg-sky-600 rounded-full"
                  key={[role?.id, role?.title].join(" ")}
                  count={role?.users_count}
                  style={{
                    backgroundColor: "rgb(2 132 199 / var(--tw-bg-opacity)",
                  }}
                />
                {role?.title}
              </span>
            </Button>
          );
        })}
      </div>
      <div className="container" style={{ marginTop: "1%" }}>
        <Button
          onClick={() => {
            UpdateRolePermission(roleid);
          }}
          className="mt-[3%] ml-[80%] bg-white-900"
          loading={loadUpdatePermission}
        >
          <SaveOutlined className="text-lg text-sky-600" />
          Save
        </Button>
        <Button
          onClick={() => {
            DeleteRole(roleid);
          }}
          className="mt-[3%] ml-[2%] bg-white-900"
          loading={loadDeleteRole}
        >
          <DeleteOutlined className="text-lg text-red-700" />
          Role
        </Button>
        <Table
          className="ml-[5%]"
          columns={columns}
          dataSource={permission_groups}
          rowKey={permission_groups?.id}
          loading={loadPermissionGroup || load}
          pagination={false}
          // pagination={{ pageSize: 20 }}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: selectedkeys,
            onChange: (keys) => {
              setSelectedkeys(keys);
            },
          }}
        />
      </div>

      <Modal
        open={addRoleModal}
        closable={false}
        onCancel={closeAddRoleModal}
        footer={null}
      >
        <AddRole closeAddRoleModal={closeAddRoleModal} />
      </Modal>
      <Modal
        open={addRoleUserModal}
        closable={false}
        onCancel={closeAddRoleUserModal}
        footer={null}
      >
        <AddRoleUser closeAddRoleUserModal={closeAddRoleUserModal} />
      </Modal>
      <Drawer
        open={roleUserDrawer}
        onClose={closeRoleUserDrawer}
        closable={false}
      >
        <RoleUsers roleForOperation={roleForOp} />
      </Drawer>
    </div>
  );
};

export default RBAC;
